import { measurementTools } from "../utils/reusable-functions";
import vec3ToSerializedString from "../utils/reusable-functions/vec3ToSerializedString";

const downGuyingMeasurementTypes = [{
    title: 'attach_height',
    id: 'attach_height',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},
{
    title: 'attach_height_proposed',
    id: 'attach_height_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},];

const sidewalkBraceMeasurementTypes = [{
    title: 'brace_height',
    id: 'brace_height',
    coord_id: 'brace_origin_coord',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},
{
    title: 'brace_origin_coord',
    id: 'brace_origin_coord',
    getMeasurement: function (measurement, ...props) {
        return vec3ToSerializedString(measurement.points[0].position)
    },
    hidden: true
},
{
    title: 'brace_length',
    id: 'brace_length',
    coord_id: 'brace_endpoint_coord',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getLength(measurement, pole_base) + 'ft'
    }
},
{
    title: 'brace_endpoint_coord',
    id: 'brace_endpoint_coord',
    getMeasurement: function (measurement, ...props) {
        return vec3ToSerializedString(measurement.points[0].position)
    },
    hidden: true
},
{
    title: 'brace_height_proposed',
    id: 'brace_height_proposed',
    coord_id: 'brace_origin_coord_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},
{
    title: 'brace_origin_coord_proposed',
    id: 'brace_origin_coord_proposed',
    getMeasurement: function (measurement, ...props) {
        return vec3ToSerializedString(measurement.points[0].position)
    },
    hidden: true
},
{
    title: 'brace_length_proposed',
    id: 'brace_length_proposed',
    coord_id: 'brace_endpoint_coord_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getLength(measurement, pole_base) + 'ft'
    }
},
{
    title: 'brace_endpoint_coord_proposed',
    id: 'brace_endpoint_coord_proposed',
    getMeasurement: function (measurement, ...props) {
        return vec3ToSerializedString(measurement.points[0].position)
    },
    hidden: true
},];

const guyingMeasurementTypes = [
    {
        title: 'anchor_location_coord',
        id: 'anchor_location_coord',
        coord_id: 'anchor_location_coord',
        getMeasurement: function (measurement, ...props) {
            return vec3ToSerializedString(measurement.points[0].position)
        },
        hidden: true
    },
    {
        title: 'lead span length',
        id: 'lead_span_length',
        coord_id: 'anchor_location_coord',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getLength(measurement, pole_base) + 'ft'
        }
    },
    {
        title: 'azimuth',
        id: 'azimuth',
        coord_id: 'anchor_location_coord',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getAzimuth(measurement, pole_base, props.projection) + 'Deg'
        },
        calculated: true
    },
    {
        title: 'delta height',
        id: 'delta_height',
        coord_id: 'anchor_location_coord',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft'
        },
        calculated: true
    },
    {
        title: 'anchor_location_coord_proposed',
        id: 'anchor_location_coord_proposed',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getLength(measurement, pole_base) + 'ft'
        },
        hidden: true
    },
    {
        title: 'lead span length proposed',
        id: 'lead_span_length_proposed',
        coord_id: 'anchor_location_coord_proposed',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getLength(measurement, pole_base) + 'ft'
        }
    },
    {
        title: 'azimuth proposed',
        id: 'azimuth_proposed',
        coord_id: 'anchor_location_coord_proposed',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getAzimuth(measurement, pole_base, props.projection) + 'Deg'
        },
        calculated: true
    },
    {
        title: 'delta height proposed',
        id: 'delta_height_proposed',
        coord_id: 'anchor_location_coord_proposed',
        getMeasurement: function (measurement, pole_base, ...props) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft';
        },
        calculated: true
    },];


export {
    downGuyingMeasurementTypes,
    sidewalkBraceMeasurementTypes,
    guyingMeasurementTypes
}