import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from '../../Contexts';
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    LinearProgress,
    ButtonGroup
} from '@mui/material';
import withAttachementManager from "./AttachmentManagerHOC";
import { DropdownValueType, StepOption } from "../../Enums";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";
import { guyingMeasurementsObjects } from "../../type-objects";
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";

const SidewalkBrace = ({ anchor, update, ...props }) => {
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
    const [measurementMap] = appContext.measurementMap;
    const [dropdownData] = appContext.dropdownData;
    const [loading, setLoading] = useState(false);
    const [validationErrors] = useState([]);
    const [formData, setFormData] = useState({
        "id": null,
        "anchor_id": anchor.id,
        "type_id": null,
        "created_by": activePole.reviewer,
        "updated_by": activePole.reviewer
    });

    const addtoAnchor = async () => {
        setLoading(true);
        guyingMeasurementsObjects.sidewalkBraceMeasurementTypes
            .forEach(type => {
                if (Object.keys(measurementMap).includes(`${type.coord_id}_${props.newAttachmentId}`)) {
                    let measurement = measurementMap[`${type.coord_id}_${props.newAttachmentId}`];
                    formData[type.id] = type.getMeasurement(measurement, measurementMap.pole_base);
                    formData[type.coord_id] =  vec3ToSerializedString(measurement.points[0].position);
                }
            })
        if (!(formData['brace_height'] && formData['brace_length'])) {
            props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
            setLoading(false);
        } else {
            props.handleErrors(null);
            formData.id = props.newAttachmentId;
            update(prevState => ([
                ...prevState,
                formData
            ]));
            // const response = await MeasurementApi.upsertWirelineComm(formDataCollected);
            // await props.handleSaveMeasurements(response, guyingMeasurementTypes);
            props.setStep(StepOption.Start);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!props.newAttachmentId) {
            props.handleStartNewAttachment();
        }
    })

    if (props.step === StepOption.Create) {
        return (
            <div className="sidewalk-brace-manager">
                <strong>
                    <ButtonGroup sx={{ float: "right" }}>
                        <Button variant="outlined" size='small' onClick={addtoAnchor} disabled={loading}>
                            Add
                        </Button>
                        <Button variant="outlined"
                            size='small'
                            color="error"
                            onClick={() => { props.handleCancelMeasurement(guyingMeasurementsObjects.sidewalkBraceMeasurementTypes); }}
                            disabled={loading}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </strong>
                <FormControl fullWidth margin="dense" error={validationErrors.includes('no_type')} size="small">
                    <InputLabel id="sidewalk-brace-type-select-label">Sidewalk Brace Type</InputLabel>
                    <Select
                        labelId="sidewalk-brace-type-select-label"
                        id="sidewalk-brace-type-select"
                        value={formData.type_id}
                        label="Sidewalk Brace Type"
                        onChange={(e) => { props.handleDropdownChange('type_id', e, setFormData) }}>
                        {dropdownData[DropdownValueType.SidewalkBraceType.type].map((menuItem, index) => (
                            <MenuItem key={index} value={menuItem.id}>{menuItem[DropdownValueType.SidewalkBraceType.labelKey]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {guyingMeasurementsObjects.sidewalkBraceMeasurementTypes
                    .filter(type => !type.hidden)
                    .map((type, index) => {
                        return (
                            <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}_${index}`}
                                attachmentId={props.newAttachmentId}
                                type={type}
                                disabled={type.calculated}
                                existingMeasurements={measurementMap}
                                handleSetPointMode={props.handleSetPointMode} />);
                    })}
                {loading && <LinearProgress />}
            </div>
        )
    }
};

const SidewalkBraceManager = withAttachementManager(SidewalkBrace);

export default SidewalkBraceManager;