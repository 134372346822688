/* eslint-disable */
import React, { useEffect, useRef, useState, useContext } from "react";
import { StoreContext, PositionContext } from '../../Contexts';
import { mapService } from "../../services";
import MapMenu from "./MapMenu";
import { Snackbar } from "@mui/material";
import PoleLayer from "./bing-map-layers/PoleLayer";
import PointCloudPolyLineLayer from "./bing-map-layers/PointCloudPolyLineLayer";
import PointCloudBoxesLayer from "./bing-map-layers/PointCloudBoxesLayer";
import PanoramaImageLayer from "./bing-map-layers/PanoramaImageLayer";
import { InteractiveSection } from "../../Enums";

const defaultLayerState = JSON.stringify({
  panoImagery: { label: 'Panoramic Imagery', enabled: true },
  pointCloudBoxes: { label: 'Point Cloud Boxes', enabled: false },
  pointCloudArea: { label: 'Point Cloud Area', enabled: true },
  poles: { label: 'Poles', enabled: true }
})

const BingMap = ({
  bingMapsKey,
}) => {
  const appContext = useContext(StoreContext);
  const positionContext = useContext(PositionContext);
  const [latitude, setLatitude] = positionContext.latitude;
  const [longitude, setLongitude] = positionContext.longitude;
  const [activeProject] = appContext.activeProject;
  const [stage] = appContext.theStage;
  const activeProjectRef = useRef({});
  activeProjectRef.current = activeProject;
  const [bingInitiated, setBingInitiated] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [latestPositionPushpin, setlatestPositionPushpin] = useState(null);
  const mapContainer = useRef(null);
  const mapRef = useRef();
  const [layerStates, setLayerStates] = useState(JSON.parse(defaultLayerState));
  const [layers, setLayers] = useState({});
  const layersRef = useRef({});
  layersRef.current = layers;



  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const initMap = () => {
    const { Maps } = window.Microsoft;
    if (!mapRef.current) {
      // only make a new map if one doesn't already exist
      mapRef.current = new Maps.Map(mapContainer.current, {
        credentials: bingMapsKey,
        disableStreetside: true
      });

      Maps.Events.addOne(mapRef.current, 'viewrendered', async () => {
        mapRef.current.setView({
          center: new Maps.Location(latitude || activeProjectRef.current.lat, longitude || activeProjectRef.current.ln),
          zoom: 17.0,
          mapTypeId: window.Microsoft.Maps.MapTypeId.aerial
        });
      });
      setMapReady(true);
    }
  }

  const updateLayerRef = (layerKey, layer) => {
    setLayers(prevState => ({
      ...prevState,
      [layerKey]: layer
    }));
  }

  const plotLatestPosition = async () => {
    if (latitude && longitude) {
      const { Maps } = window.Microsoft;
      mapRef.current.entities.remove(latestPositionPushpin);
      let pushpin = new Maps.Pushpin({
        latitude: latitude,
        longitude: longitude,
      }, { color: 'yellow' })
      setlatestPositionPushpin(pushpin);
      mapRef.current.entities.push(pushpin);
      mapRef.current.setView({
        center: new Maps.Location(latitude, longitude)
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      if (!bingInitiated) {
        await mapService.loadBingApi(bingMapsKey);
        setBingInitiated(true);
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (window.Microsoft && window.Microsoft.Maps) {
      initMap();
    }
  }, [bingInitiated, activeProjectRef.current]);

  useEffect(() => {
    if (mapRef.current) {
      Object.entries(layerStates).forEach(([layerKey, layerState]) => {
        let layer = mapService.getLayer(layerKey, mapRef.current);
        layer?.setVisible(layerState.enabled);
        updateLayerRef(layerKey, layer);
      })
    }
  }, [layerStates])

  useEffect(() => {
    if (mapRef.current) {
      plotLatestPosition();
    }
  }, [latitude, longitude, mapRef.current]);

  return (
    <>
      <div ref={mapContainer} style={{ height: "100%", width: "100%" }}></div>
      {mapReady && <>
        <PointCloudPolyLineLayer
          map={mapRef.current}
          visible={layerStates.pointCloudArea.enabled}
          layerRef={layersRef.current.pointCloudArea}
          updateLayer={updateLayerRef} />
        <PointCloudBoxesLayer
          map={mapRef.current}
          visible={layerStates.pointCloudBoxes.enabled}
          layerRef={layersRef.current.pointCloudBoxes}
          updateLayer={updateLayerRef} />
        <PanoramaImageLayer
          map={mapRef.current}
          visible={layerStates.panoImagery.enabled}
          layerRef={layersRef.current.panoImagery}
          updateLayer={updateLayerRef} />
        <PoleLayer
          map={mapRef.current}
          visible={layerStates.poles.enabled}
          layerRef={layersRef.current.poles}
          updateLayer={updateLayerRef} />
      </>}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        message={snackbarMessage}
        onClose={handleClose}
      />
      {stage === InteractiveSection.Map && mapReady &&
        <MapMenu
          currentMap={mapRef.current}
          currentLayers={layersRef.current}
          layerStates={layerStates}
          setLayerStates={setLayerStates}
          defaultLayerState={defaultLayerState} />
      }
    </>
  );
};

export default BingMap;