import { DropdownValueType, InputType } from "../Enums";
import { measurementTools } from "../utils/reusable-functions";

const poleInputTypeObjects = [{
    id: 'pole_agl',
    label: 'Pole AGL',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    },
    inputType: InputType.MeasurementInputControl
},
{
    id: 'pole_length',
    label: 'Length',
    inputType: InputType.Select,
    dropdownValueType: DropdownValueType.PoleHeight,
    defaulValue: null
},
{
    id: 'reviewer',
    label: 'Reviewer',
    inputType: InputType.Text
},
{
    id: 'pole_tag_1',
    label: 'Tag 1',
    inputType: InputType.Text
},
{
    id: 'pole_tag_2',
    label: 'Tag 2',
    inputType: InputType.Text
},
{
    id: 'pole_tag_3',
    label: 'Tag 3',
    inputType: InputType.Text
},
{
    id: 'pole_material',
    label: 'Material',
    inputType: InputType.Select,
    dropdownValueType: DropdownValueType.PoleMaterial,
    defaulValue: null
},
{
    id: 'pole_class',
    label: 'Class',
    inputType: InputType.Select,
    dropdownValueType: DropdownValueType.PoleClass,
    defaulValue: null
},
{
    id: 'pole_species',
    label: 'Species',
    inputType: InputType.Select,
    dropdownValueType: DropdownValueType.PoleSpecies,
    defaulValue: null
},
{
    id: 'pole_ground',
    label: 'Ground',
    inputType: InputType.Select,
    dropdownValueType: DropdownValueType.PoleGround,
    defaulValue: null
},
{
    id: 'pole_field_notes',
    label: 'Field Notes',
    inputType: InputType.Text
},
{
    id: 'pole_owner',
    label: 'Owner',
    disabled: true,
    inputType: InputType.Text
},
{
    id: 'fielder',
    label: 'Fielder',
    disabled: true,
    inputType: InputType.Text
},
{
    id: 'pole_base',
    label: 'Elevation',
    getMeasurement: function (measurement, pole_base, poleSchema) {
        return measurementTools.getElevation(measurement, pole_base) + 'ft'
    },
    inputType: InputType.Calculated
},
{
    id: 'lat',
    label: 'Latitude',
    getMeasurement: function (measurement, pole_base, poleSchema) {
        return poleSchema.lat
    },
    inputType: InputType.Calculated
},
{
    id: 'lng',
    label: 'Longitude',
    getMeasurement: function (measurement, pole_base, poleSchema) {
        return poleSchema.lng
    },
    inputType: InputType.Calculated
},];

export { poleInputTypeObjects };