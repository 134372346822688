/* eslint-disable */
import React, { useContext, useState } from "react";
import { StoreContext } from '../../Contexts';
import { CommsApi } from "../../api";
import {
  IconButton,
  ButtonGroup,
  Button,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  LinearProgress
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";
import withAttachmentManager from "./AttachmentManagerHOC";
import { StepOption } from "../../Enums";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";
import { attachmentMeasurementTypeObjects } from "../../type-objects";

const WirelessComms = (props) => {
  const appContext = useContext(StoreContext);
  const [activePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [measurementMap] = appContext.measurementMap;
  const [loading, setLoading] = useState(false);

  const saveMeasurements = async () => {
    setLoading(true);
    const errors = [];

    const formDataCollected = {
      "antenna_top": '',
      "antenna_top_coord": '',
      "antenna_attached": '',
      "antenna_attached_coord": '',
      "antenna_bottom": '',
      "antenna_bottom_coord": '',
      "antenna_top_proposed": '',
      "antenna_top_proposed_coord": '',
      "antenna_attached_proposed": '',
      "antenna_attached_proposed_coord": '',
      "antenna_bottom_proposed": '',
      "antenna_bottom_proposed_coord": '',
      "created_by": activePole.reviewer,
      "updated_by": activePole.reviewer,
      "pole_id": activePole.id,
    }

    attachmentMeasurementTypeObjects.wirelessMeasurementTypes.forEach(type => {
      if (Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`)) {
        const measurement = measurementMap[`${type.id}_${props.newAttachmentId}`];
        formDataCollected[type.title] = type.getMeasurement(measurement, measurementMap.pole_base);
        formDataCollected[`${type.title}_coord`] = vec3ToSerializedString(measurement.points[0].position);
      }
    })

    // do validation. 
    // you must have at least one measurement to continue. 
    if (!attachmentMeasurementTypeObjects.wirelessMeasurementTypes.some(type => formDataCollected[type.title] !== null)) {
      props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
    } else {
      props.handleErrors(null);
      const response = await CommsApi.upsertWirelessComm(formDataCollected);
      await props.handleSaveMeasurements(response, attachmentMeasurementTypeObjects.wirelessMeasurementTypes);
    }
    setLoading(false);
  }


  if (props.step === StepOption.Start) {
    return (
      <div className="comms-manager">
        <strong>
          Wireless
          <IconButton size="small" onClick={() => { props.handleStartNewAttachment(); }} sx={{ float: 'right' }} color="primary">
            <AddCircleOutlineOutlined />
          </IconButton>
        </strong>
        {activePole.wireless_comms.length > 0 &&
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  {attachmentMeasurementTypeObjects.wirelessMeasurementTypes.map(type =>
                    <TableCell align="center">{type.title}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {activePole.wireless_comms.map((comm, index) => (
                  <TableRow
                    key={comm.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
                    <TableCell component="th" scope="row">
                      #{index + 1}
                    </TableCell>
                    {attachmentMeasurementTypeObjects.wirelessMeasurementTypes.map(type =>
                      <TableCell key={`${type.id}_${comm.id}`} align="center">{measurementMap[`${type.id}_${comm.id}`]
                        ? type.getMeasurement(measurementMap[`${type.id}_${comm.id}`], measurementMap.pole_base)
                        : 'n/a'}</TableCell>)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
      </div>
    );
  }

  if (props.step === StepOption.Create) {
    return (
      <div className="comms-manager">
        <strong>Wireless
          <ButtonGroup sx={{ float: "right" }}>
            <Button variant="outlined" size='small' onClick={saveMeasurements} disabled={loading}>
              Save
            </Button>
            <Button variant="outlined"
              size='small'
              color="error"
              onClick={() => { props.handleCancelMeasurement(attachmentMeasurementTypeObjects.wirelessMeasurementTypes); }}
              disabled={loading}>
              Cancel
            </Button>
          </ButtonGroup>
        </strong>
        {attachmentMeasurementTypeObjects.wirelessMeasurementTypes.map(type => {
          return (
            <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}`}
              attachmentId={props.newAttachmentId}
              type={type}
              existingMeasurements={measurementMap}
              handleSetPointMode={props.handleSetPointMode} />);
        })}
        {loading && <LinearProgress />}
      </div>
    )
  }
};

const WirelessCommsManager = withAttachmentManager(WirelessComms);

export default WirelessCommsManager;