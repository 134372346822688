/* eslint-disable */

import React, { useContext, useEffect } from "react";
import { StoreContext } from '../Contexts';
import { useLocation } from "react-router-dom";
import { InteractiveSection, LayoutOption } from "../Enums";
import PhotoSphereViewer from "../features/photo-sphere-viewer/PhotoSphereViewer";

const PanoImageryOne = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appContext = useContext(StoreContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [imageId, setImageId] = appContext.imageId;

  useEffect(() => {
    setImageId(queryParams.get("image_id"));
    setLayout(LayoutOption.FullPage);
    setTheStage(InteractiveSection.Panorama);
  }, []);


  return (
    <div className="stage">
      <PhotoSphereViewer />
    </div>
  );
}

export default PanoImageryOne;
