import React, { useRef, useState, useEffect, useContext } from "react";
import {
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Stack,
    Switch,
    Button,
    TextField,
    InputAdornment
} from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import SearchIcon from '@mui/icons-material/Search';
import { mapService } from "../../services";
import { PoleApi } from "../../api";
import { StoreContext } from "../../Contexts";

const MapMenu = (
    { currentMap,
        currentLayers,
        layerStates,
        setLayerStates,
        defaultLayerState }, ...props) => {
    const { Maps } = window.Microsoft;
    const appContext = useContext(StoreContext);
    const [activeProject] = appContext.activeProject;
    const [tools, setTools] = useState(null);
    const [searchArea, setSearchArea] = useState();
    const searchAreaRef = useRef();
    searchAreaRef.current = searchArea;

    const [selectedPins, setSelectedPins] = useState([]);
    const selectedPinsRef = useRef([]);
    selectedPinsRef.current = selectedPins;

    const [searchManager, setSearchManager] = useState();
    const [searchValue, setSearchValue] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleToggleLayersClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const drawSearchArea = () => {
        //Create a new polygon.
        tools.create(Maps.DrawingTools.ShapeType.polygon, function (s) {
            setSearchArea(s);
        });
        Object.entries(layerStates).forEach(([layerKey, layerState]) => {
            layerState.enabled = layerKey === 'poles' ? true : false;
        })
        setLayerStates(prevState => ({
            ...prevState,
            ...layerStates
        }))
    }

    const clearSearchArea = (layerName, defaultColor) => {
        if (searchAreaRef.current) {
            tools.finish();
            var pins = currentLayers[layerName].getPrimitives();
            mapService.resetPins(pins, defaultColor);
            setSelectedPins([]);
            setSearchArea(null);
        }
        Object.keys(layerStates).forEach((layerKey) =>
            layerStates[layerKey].enabled = JSON.parse(defaultLayerState)[layerKey].enabled
        )
        setLayerStates(prevState => ({
            ...prevState,
            ...layerStates
        }));
    }

    const toggleLayer = (layerKey) => {
        layerStates[layerKey].enabled = !layerStates[layerKey].enabled;
        setLayerStates((prevState) => ({
            ...prevState,
            [layerKey]: layerStates[layerKey]
        }))
    }

    const clearLayers = () => {
        Object.keys(layerStates).forEach((layerKey) =>
            layerStates[layerKey].enabled = false
        )
        setLayerStates({ ...layerStates });
    }

    const handleSearch = async () => {
        /^[0-9]*$/.test(searchValue) ?
            await getPoleLocation() :
            geoCode(searchValue);
    }

    const getPoleLocation = async () => {
        let pole = await PoleApi.getPoleById(searchValue);
        pole.project_name === activeProject.collection_name
            ? currentMap.setView({
                center: new Maps.Location(pole.lat, pole.lng),
                zoom: 100
            })
            : alert(`Pole ${searchValue} is in ${pole.project_name}`);
    }

    const geoCode = (where) => {
        let searchRequest = {
            where: where,
            callback: (r, userData) => {
                if (r && r.results && r.results.length > 0) {
                    let locs = [];
                    r.results.map((result => locs.push(result.location)));
                    if (r.results.length === 1) {
                        currentMap.setView({ bounds: r.results[0].bestView, padding: 5 });
                    } else {
                        alert(`Multiple results found, refine search: ${JSON.stringify(locs)}`)
                    }
                }
            },
            errorCallback: (e) => {
                alert(`Invalid search value: ${e.where}`);
                console.log(e);
            }
        };
        searchManager.geocode(searchRequest);
    }

    useEffect(() => {
        const suggestionSelected = (result) => {
            setSearchValue(result.title);
            currentMap.setView({ bounds: result.bestView });
        }

        const initMenu = () => {
            Maps.loadModule(['Microsoft.Maps.DrawingTools', 'Microsoft.Maps.SpatialMath'], function () {
                let drawingTools = new Maps.DrawingTools(currentMap);
                setTools(drawingTools);
                Maps.Events.addHandler(drawingTools, 'drawingChanged', function (s) {
                    mapService.findPinsInArea(currentLayers.poles, s, 'red', 'purple', setSelectedPins);
                });
            });
            Maps.loadModule(['Microsoft.Maps.AutoSuggest', 'Microsoft.Maps.Search'], function () {
                var manager = new Maps.AutosuggestManager({ map: currentMap });
                manager.attachAutosuggest('#searchBox', '#searchBoxContainer', suggestionSelected);
                setSearchManager(new Maps.Search.SearchManager(currentMap));
            });
        }

        initMenu();
    }, [Maps, currentLayers.poles, currentMap]);

    return (
        <Stack sx={{ position: 'absolute', top: 5, left: 5, background: 'transparent' }} spacing={.5}>
            <div id='searchBoxContainer'>
                <TextField
                    id='searchBox'
                    size="small"
                    placeholder="Address,GPS,Pole ID"
                    onChange={(e) => setSearchValue(e.target.value)}
                    sx={{
                        background: 'white',
                        borderRadius: '5px',
                        input: {
                            borderRadius: '5px',
                            fontSize: '10pt'
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label="search"
                                    disabled={!searchValue}
                                    onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <div>
                <Tooltip title="Clear Layers" arrow>
                    <Button
                        sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                        aria-label="clear layers"
                        onClick={clearLayers}>
                        <LayersClearIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Toggle Layers" arrow>
                    <Button
                        sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                        aria-label="toggle layers"
                        onClick={handleToggleLayersClick}>
                        <LayersIcon />
                    </Button>
                </Tooltip>
            </div>
            <div>
                {searchAreaRef.current ?
                    <Tooltip title="Clear Selected Poles" arrow>
                        <Button
                            sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                            aria-label="clear selected"
                            onClick={() => { clearSearchArea('poles', 'red') }}>
                            <ClearIcon />
                        </Button>
                    </Tooltip> :
                    <Tooltip title="Select Poles" arrow>
                        <Button
                            sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                            aria-label="select pins"
                            onClick={drawSearchArea}>
                            <HighlightAltIcon />
                        </Button>
                    </Tooltip>
                }
                {selectedPins.length &&
                    <Tooltip title="Download Selected Poles" arrow>
                        <Button
                            sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                            aria-label="download selected poles" onClick={() => {
                                alert(selectedPins.map(pole => pole.id).toString())
                            }} >
                            <DownloadIcon />
                        </Button>
                    </Tooltip>}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {Object.keys(currentLayers).map((layer) => {
                    return <MenuItem key={layer}>
                        <Switch
                            size='small'
                            checked={layerStates[layer].enabled}
                            onChange={() => toggleLayer(layer)}
                            inputProps={{ 'aria-label': 'controlled' }} />
                        {layerStates[layer].label}</MenuItem>
                })}
            </Menu>
        </Stack>)
}

export default MapMenu;