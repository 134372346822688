import React from "react";
import MapStage from "../features/map-stage/MapStage";
import PhotoSphereViewer from "../features/photo-sphere-viewer/PhotoSphereViewer";
import PotreeViewer from "../features/potree-viewer/PotreeViewer";
import MeasurementSchema from "../features/measurement-schema/MeasurementSchema";
import RequestGroundCollection from "../features/request-ground-collection/RequestGroundCollection";
import PoleInfoPanel from "../features/pole-info-panel/PoleInfoPanel";
import DummyTestSection from "../features/dummy-test-section/DummyTestSection";
import MeasureTools from "../features/measure-tools/MeasureTools";
import { InteractiveSection } from "../Enums";
import { ErrorBoundary } from "react-error-boundary";
import AddPoleButton from "../features/add-pole-button/AddPoleButton";

const fallbackRender = ({ error, resetErrorBoundary }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const getSectionComponent = ({ section }) => {
  switch (section) {
    case InteractiveSection.PoleInfoPanel: return (<PoleInfoPanel />);
    case InteractiveSection.Measurements: return (<MeasurementSchema />);
    case InteractiveSection.RequestGroundCollection: return (<RequestGroundCollection />);
    case InteractiveSection.Map: return (<MapStage />);
    case InteractiveSection.Panorama: return (<PhotoSphereViewer />);
    case InteractiveSection.PointCloud: return (<PotreeViewer />);
    case InteractiveSection.Dummy: return (<DummyTestSection />);
    case InteractiveSection.MeasureTools: return (<MeasureTools />);
    case InteractiveSection.AddPoleButton: return (<AddPoleButton />)
    // render the empty toolbar
    default:
      return (
        <></>
      )
  }
}

const logError = (error, info) => {
  console.log(`BOUNDARY ERROR: ${error}, ${info}`)
};

const InteractiveSectionSelector = ({ section }) => {
  return <ErrorBoundary
    fallbackRender={fallbackRender}
    onError={logError}
    className='error-boundary'
  >
    {getSectionComponent({ section })}
  </ErrorBoundary>
};

export default InteractiveSectionSelector;
