import React from "react";
import { Paper, Stack, styled } from "@mui/material";
import TransmissionManager from "./TransmissionManager";
import DistributionManager from "./DistributionManager";
import WirelessCommsManager from "./WirelessCommsManager";
import WirelineCommsManager from "./WirelineCommsManager";
import GuyingManager from "./GuyingManager";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const ComponentList = [TransmissionManager, DistributionManager, WirelessCommsManager, WirelineCommsManager, GuyingManager];

export default function AttachmentManager() {
  return (
    <Stack className="attachment-manager-wrap" spacing={.5}>
      {ComponentList.map((Component, key) =>
        <Item key={`item_${key}`}>
          <Component key={key} />
        </Item>)}
    </Stack>
  )
}