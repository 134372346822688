/* eslint-disable */

// will load on map-one route. 
import React, { useState, useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { StoreContext } from '../Contexts';
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";


const PointcloudOne = () => {
  // router stuff. 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const collection_name = queryParams.get("collection_name");
  const collection_id = queryParams.get("collection_id");
  const collection_set = queryParams.get("collection_set");
  const central_lng = queryParams.get("central_lng");
  const crs = queryParams.get("crs");
  const pointcloud_id = queryParams.get("pointcloud_id");

  const appContext = useContext(StoreContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;

  const fetchData = async () => {
    setActivePointcloud({
      collection_set,
      collection_id,
      collection_name,
      crs,
      pointcloud_id // the id of the clicked pointcloud bounding box.
    });

  }

  const getActiveProject = async (collectionName) => {
    // if activeProject is not set then set it. we need the project id for certain things. 
    if (activeProject === null) {
      const projectList = await ProjectApi.fetchProjectList();
      // find the active project and set it to the state so we can use it in future interactions.
      const theActiveProject = projectList.find(project => project.collection_name === collectionName);
      setActiveProject(theActiveProject);
      return theActiveProject;
    }
    return null;
  }

  useEffect(() => {
    setLayout(LayoutOption.SideBars);;
    fetchData();
    setTheStage(InteractiveSection.PointCloud);
    setSidebarModules({
      left_top: InteractiveSection.Dummy, // top sidebar left (toolbar 1)
      left_bottom: InteractiveSection.Dummy, //bottom sidebar left (toolbar 1)
      right_top: InteractiveSection.Measurements, // bottom sidebar right (toolbar 2)
      right_bottom: InteractiveSection.Dummy,
    });
    getActiveProject(collection_name);

  }, []);

  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default PointcloudOne;
