export const InteractiveSection = {
  PoleInfoPanel: 'pole-info-panel',
  Measurements: 'measurements',
  RequestGroundCollection: 'request-ground-collection',
  Map: 'map',
  Panorama: 'panorama',
  PointCloud: 'point-cloud',
  Dummy: 'dummy',
  MeasureTools: 'generic-measure-tools',
  AddPoleButton: 'add-pole-button'
};  