import proj4 from "proj4";
import { MathUtils, Vector3 } from "three";

function metersToFeet(meters) {
    // The conversion rate from meters to feet.
    const conversionRate = 3.2808399;
    // Convert the meters to feet.
    const feet = meters * conversionRate;
    // Round the feet to 2 decimal places.
    return feet.toFixed(2);
}

//copied from potree repo
function computeAzimuth(p1, p2, projection) {
    let azimuth = 0;
    if (projection) {
        // if there is a projection, transform coordinates to WGS84
        // and compute angle to north there
        let transform;

        if (projection.includes('EPSG')) {
            transform = proj4(projection, "WGS84");
        } else {
            proj4.defs("pointcloud", projection);
            transform = proj4("pointcloud", "WGS84");
        }

        const llP1 = transform.forward(p1.toArray());
        const llP2 = transform.forward(p2.toArray());
        const dir = [
            llP2[0] - llP1[0],
            llP2[1] - llP1[1],
        ];
        azimuth = Math.atan2(dir[1], dir[0]) - Math.PI / 2;
    } else {
        // if there is no projection, assume [0, 1, 0] as north direction
        const dir = [p2.x - p1.x, p2.y - p1.y];
        azimuth = Math.atan2(dir[1], dir[0]) - Math.PI / 2;
    }

    // make clockwise
    let azimuthDeg = MathUtils.radToDeg(-azimuth);
    if (azimuthDeg < 0) {
        azimuthDeg = 360 + azimuthDeg;
    }
    return azimuthDeg.toFixed(2);
}


const getHeight = (measurement, base) => {
    if (measurement && base) {
        const height = measurement.points[0].position.z - base.points[0].position.z;
        return metersToFeet(height);
    }
}


const getElevation = (measurement) => {
    if (measurement) {
        return metersToFeet(measurement.points[0].position.z)
    }
}

const getLength = (measurement, base) => {
    if (measurement && base) {
        const pole = new Vector3(base.points[0].position.x,
            base.points[0].position.y,
            measurement.points[0].position.z);
        const length = measurement.points[0].position.distanceTo(pole);
        return metersToFeet(length);
    }
}

const getDistance = (measurement, base) => {
    if (measurement && base) {
        const distance = measurement.points[0].position.distanceTo(base.points[0].position);
        return metersToFeet(distance);
    }
}

const getAzimuth = (measurement, base, projection) => {
    if (measurement && base) {
        return computeAzimuth(base.points[0].position, measurement.points[0].position, projection);
    }
}

export {
    metersToFeet,
    computeAzimuth,
    getHeight,
    getElevation,
    getLength,
    getDistance,
    getAzimuth
}