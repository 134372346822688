import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from '../../Contexts';
import {
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    LinearProgress,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableContainer,
    Paper,
    ButtonGroup,
    Badge
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import withAttamchementManager from "./AttachmentManagerHOC";
import { DropdownValueType, StepOption } from "../../Enums";
import DownGuyingManager from "./DownGuyManager";
import SidewalkBraceManager from "./SidewalkBraceManager";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";
import { guyingMeasurementsObjects } from "../../type-objects";
import { GuyingApi } from "../../api";

const Guying = (props) => {
    const appContext = useContext(StoreContext);
    const [activePole, setActivePole] = appContext.activePole;
    const [measurementMap] = appContext.measurementMap;
    const [dropdownData] = appContext.dropdownData;
    const [potreeViewer] = appContext.potreeViewer;
    const [loading, setLoading] = useState(false);
    const [validationErrors] = useState([]);
    const [formData, setFormData] = useState({
        "id": "",
        "pole_id": activePole.id,
        "owner_id": "1",
        "guy_type": null,
        "anchor_location_coord": null,
        "lead_span_length": null,
        "azimuth": null,
        "anchor_rod_diameter_id": null,
        "delta_height": null,
        "owner_id_proposed": "1",
        "guy_type_proposed": null,
        "anchor_location_coord_proposed": null,
        "lead_span_length_proposed": null,
        "azimuth_proposed": null,
        "anchor_rod_diameter_id_proposed": null,
        "delta_height_proposed": null,
        "created_by": activePole.reviewer,
        "updated_by": activePole.reviewer,
    });
    const [downGuys, setDownGuys] = useState([]);
    const [sidewalkBraces, setSidewalkBraces] = useState([]);
    const [downGuyComponentList, setDownGuyComponentList] = useState([]);
    const [sidewalkBraceComponentList, setSidewalkBraceComponentList] = useState([]);



    const onAddDownGuyBtnClick = () => {
        setDownGuyComponentList(downGuyComponentList.concat(<DownGuyingManager
            key={downGuyComponentList.length}
            anchor={formData}
            update={setDownGuys} />));
    };

    const onAddSidewalkBraceBtnClick = () => {
        setSidewalkBraceComponentList(sidewalkBraceComponentList.concat(<SidewalkBraceManager
            key={sidewalkBraceComponentList.length}
            anchor={formData}
            update={setSidewalkBraces} />));
    };

    const saveMeasurements = async () => {
        setLoading(true);
        guyingMeasurementsObjects.guyingMeasurementTypes
            .forEach(type => {
                if (Object.keys(measurementMap).includes(`${type.coord_id}_${props.newAttachmentId}`)) {
                    formData[type.id] = type.getMeasurement(measurementMap[`${type.coord_id}_${props.newAttachmentId}`], measurementMap.pole_base, potreeViewer.getProjection());
                }
            })
        if (!guyingMeasurementsObjects.guyingMeasurementTypes
            .some(type => formData[type] !== null)) {
            props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
            setLoading(false);
        } else {
            props.handleErrors(null);
            activePole.anchor = activePole.anchors.map(obj => [formData].find(o => o.id === obj.id) || obj);
            setActivePole(activePole);
            const response = await GuyingApi.upsertGuyingAnchor(formData);
            await props.handleSaveMeasurements(response, guyingMeasurementsObjects.guyingMeasurementTypes);
            let anchor_id = response.insert_id;
            await downGuys.map(async (downGuyData) => { await saveDownGuys(downGuyData, anchor_id) });
            await sidewalkBraces.map(async (sidewalkBraceData) => { saveSidewalkBraces(sidewalkBraceData, anchor_id) });
            props.setStep(StepOption.Start);
            setLoading(false);
        }
    }

    const saveDownGuys = async (downGuyData, anchorId) => {
        downGuyData.anchor_id = anchorId;
        downGuyData.id = '';
        if (!guyingMeasurementsObjects.downGuyingMeasurementTypes
            .some(type => formData[type.title] !== null)) {
            props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
            setLoading(false);
        } else {
            props.handleErrors(null);
            const response = await GuyingApi.upsertDownGuy(downGuyData);
            await props.handleSaveMeasurements(response, guyingMeasurementsObjects.downGuyingMeasurementTypes);
        }
    }

    const saveSidewalkBraces = async (sidewalkBraceData, anchorId) => {
        sidewalkBraceData.anchor_id = anchorId;
        sidewalkBraceData.id = '';
        if (!guyingMeasurementsObjects.sidewalkBraceMeasurementTypes
            .some(type => formData[type.title] !== null)) {
            props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
            setLoading(false);
        } else {
            props.handleErrors(null);
            const response = await GuyingApi.upsertSidewalkBrace(sidewalkBraceData);
            await props.handleSaveMeasurements(response, guyingMeasurementsObjects.sidewalkBraceMeasurementTypes);
        }
    }

    useEffect(() => {
        if (!activePole.anchors) {
            setActivePole({ ...activePole, anchors: [] })
        }
    }, [activePole, setActivePole])

    if (props.step === StepOption.Start) {
        return (
            <div className="guying-manager">
                <strong>
                    Guying
                    <IconButton size="small"
                        onClick={() => { props.handleStartNewAttachment(); }}
                        sx={{ float: 'right' }}
                        color="primary">
                        <AddCircleOutlineOutlined />
                    </IconButton>
                </strong>
                {activePole.anchors?.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            {activePole.anchors.map((anchor, index) => (
                                <TableBody sx={{ '&:not(:first-of-type)': { borderTop: 5 } }}>
                                    <TableRow sx={{ borderBottom: 1 }}>
                                        <TableCell>Anchor ID</TableCell>
                                        <TableCell>Type</TableCell>
                                        {guyingMeasurementsObjects.guyingMeasurementTypes.filter(type => !type.hidden).map((type, index) =>
                                            <TableCell key={`${type.id}_header_${index}`} align="center">{type.title}</TableCell>)}
                                    </TableRow>
                                    <TableRow
                                        key={anchor.id} >
                                        <TableCell component="th" scope="row">
                                            #{index + 1}
                                        </TableCell>
                                        <TableCell align="center">
                                            {dropdownData[DropdownValueType.GuyAnchorDiameter.type]
                                                .find((obj) => obj.id === parseInt(anchor.anchor_rod_diameter_id))?.[DropdownValueType.GuyAnchorDiameter.labelKey]}
                                        </TableCell>
                                        {guyingMeasurementsObjects.guyingMeasurementTypes.filter(type => !type.hidden).map((type, index) =>
                                            <TableCell key={`${type.id}_${anchor.id}_${index}`} align="center">
                                                {measurementMap[`${type.coord_id ?? type.id}_${anchor.id ?? index}`]
                                                    ? type.getMeasurement(measurementMap[`${type.coord_id ?? type.id}_${anchor.id}`], measurementMap.pole_base)
                                                    : 'n/a'}
                                            </TableCell>)}
                                    </TableRow>
                                    {anchor.guy.length > 0 &&
                                        <>
                                            <TableRow>
                                                <TableCell>Down Guy ID</TableCell>
                                                <TableCell>Type</TableCell>
                                                {guyingMeasurementsObjects.downGuyingMeasurementTypes.map((type, index) =>
                                                    <TableCell key={`${type.title}_${index}`} align="center">{type.title}</TableCell>)}
                                            </TableRow>
                                            {anchor.guy.map((down_guy, index) => (
                                                <TableRow
                                                    key={`${down_guy.id}_${index}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell component="th" scope="row">
                                                        #{index + 1}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {dropdownData[DropdownValueType.DownGuyType.type]
                                                            .find((obj) => obj.id === parseInt(down_guy.guy_diameter_id))[DropdownValueType.DownGuyType.labelKey]}
                                                    </TableCell>
                                                    {guyingMeasurementsObjects.downGuyingMeasurementTypes.map((type, index) =>
                                                        <TableCell key={`${type.id}_${down_guy.id}_${index}`} align="center">
                                                            {measurementMap[`${type.id}_${down_guy.id}`]
                                                                ? type.getMeasurement(measurementMap[`${type.id}_${down_guy.id}`], measurementMap.pole_base)
                                                                : 'n/a'}
                                                        </TableCell>)}
                                                </TableRow>
                                            ))}
                                        </>
                                    }
                                    {anchor.guy_sidewalkbrace.length > 0 &&
                                        <>
                                            <TableRow>
                                                <TableCell>Brace ID</TableCell>
                                                <TableCell>Type</TableCell>
                                                {guyingMeasurementsObjects.sidewalkBraceMeasurementTypes.filter(type => !type.hidden).map(type =>
                                                    <TableCell key={type.title} align="center">{type.title}</TableCell>)}
                                            </TableRow>
                                            {anchor.guy_sidewalkbrace.map((brace, index) => (
                                                <TableRow
                                                    key={brace.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell component="th" scope="row">
                                                        #{index + 1}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {dropdownData[DropdownValueType.SidewalkBraceType.type]
                                                            .find((obj) => obj.id === parseInt(brace.type_id))[DropdownValueType.SidewalkBraceType.labelKey]}
                                                    </TableCell>
                                                    {guyingMeasurementsObjects.sidewalkBraceMeasurementTypes.filter(type => !type.hidden).map((type, index) =>
                                                        <TableCell key={`${type.id}_${brace.id}_${index}`} align="center">
                                                            {measurementMap[`${type.coord_id ?? type.id}_${brace.id}`]
                                                                ? type.getMeasurement(measurementMap[`${type.coord_id ?? type.id}_${brace.id}`], measurementMap.pole_base)
                                                                : 'n/a'}
                                                        </TableCell>)}
                                                </TableRow>
                                            ))}
                                        </>
                                    }
                                </TableBody>
                            ))}
                        </Table>
                    </TableContainer>)
                }

            </div >
        );
    }

    if (props.step === StepOption.ListData) {
        activePole.anchors.map((anchor, index) => (
            <div key={`${anchor.id}_${index}`} className="attachment-list-item">
                <p>{anchor.anchor_location_coord !== null ? `Existing: ${anchor.anchor_location_coord}ft` : ''}</p>
                <p>{anchor.anchor_location_coord_proposed !== null ? `Proposed: ${anchor.anchor_location_coord_proposed}ft` : ''}</p>
                <p>{anchor.type !== null ? `Type: Guying` : ''}</p>
            </div>))
    }

    if (props.step === StepOption.Create) {
        return (
            <div className="guying-manager">
                <strong>Guying
                    <ButtonGroup sx={{ float: "right" }}>
                        <Button variant="outlined" size='small' onClick={saveMeasurements} disabled={loading}>
                            Save
                        </Button>
                        <Button variant="outlined"
                            size='small'
                            color="error"
                            onClick={() => { props.handleCancelMeasurement(guyingMeasurementsObjects.guyingMeasurementTypes.filter(x => !x.calculated)); }}
                            disabled={loading}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </strong>
                <FormControl fullWidth margin="dense" error={validationErrors.includes('no_type')} size="small">
                    <InputLabel id="anchor-type-select-label">Anchor Type</InputLabel>
                    <Select
                        labelId="anchor-type-select-label"
                        id="anchor-type-select"
                        value={formData.anchor_rod_diameter_id}
                        label="Anchor Type"
                        onChange={(e) => { props.handleDropdownChange('anchor_rod_diameter_id', e, setFormData) }}>
                        {dropdownData[DropdownValueType.GuyAnchorDiameter.type].map((menuItem, index) => (
                            <MenuItem key={index} value={menuItem.id}>{menuItem[DropdownValueType.GuyAnchorDiameter.labelKey]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" error={validationErrors.includes('no_owner')} size="small">
                    <InputLabel id="owner-type-select-label">Owner</InputLabel>
                    <Select
                        labelId="owner-type-select-label"
                        id="owner-type-select"
                        value={formData.owner_id}
                        label="Owner"
                        onChange={(e) => { props.handleDropdownChange('owner_id', e, setFormData) }}>
                        {dropdownData[DropdownValueType.AnchorOwner.type].map((menuItem, index) => (
                            <MenuItem key={index} value={menuItem.id}>{menuItem[DropdownValueType.AnchorOwner.labelKey]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {guyingMeasurementsObjects.guyingMeasurementTypes
                    .filter(type => !type.hidden)
                    .map((type, index) => {
                        return (
                            <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}_${index}`}
                                attachmentId={props.newAttachmentId}
                                type={type}
                                existingMeasurements={measurementMap}
                                handleSetPointMode={props.handleSetPointMode}
                                disabled={type.calculated}
                                projection={potreeViewer.getProjection()} />);
                    })}
                <div>
                    <Badge badgeContent={downGuys.length} color="primary">
                        Down Guy
                    </Badge>
                    <IconButton variant="outlined"
                        size="small"
                        onClick={onAddDownGuyBtnClick}
                        sx={{ float: 'right' }}
                        color="primary">
                        <AddCircleOutlineOutlined />
                    </IconButton>
                    {downGuyComponentList}
                </div>
                <div>
                    <Badge badgeContent={sidewalkBraces.length} color="primary">
                        Sidewalk Brace
                    </Badge>
                    <IconButton variant="outlined"
                        size="small"
                        onClick={onAddSidewalkBraceBtnClick}
                        sx={{ float: 'right' }}
                        color="primary">
                        <AddCircleOutlineOutlined />
                    </IconButton>
                    {sidewalkBraceComponentList}
                </div>
                {loading && <LinearProgress />}
            </div>
        )
    }
};

const GuyingManager = withAttamchementManager(Guying);

export default GuyingManager;