
import { createContext, useState } from 'react'
import { LayoutOption } from '../Enums';

export const StoreContext = createContext(null)

const StoreProvider = ({ children }) => {
  const [poles, setPoles] = useState([]);
  const [layout, setLayout] = useState(LayoutOption.SideBars);
  const [theStage, setTheStage] = useState(null);
  const [dropdownData, setDropdownData] = useState(null);
  const [measurementMap, setMeasureMentMap] = useState({});
  const [imageId, setImageId] = useState();
  const [map, setMap] = useState();

  // layout-full-stage 
  // layout-sidebar-left
  // layout-sidebar-right
  // ''  empty string for both sidebars. 
  const [potreeViewer, setPotreeViewer] = useState(null);

  // this is used for setting which component goes in which area of the sidebars. 
  const [sideBarModules, setSidebarModules] = useState({
    leaf_header: null,
    left_top: null, // top sidebar left (toolbar 1)
    left_bottom: null, //bottom sidebar left (toolbar 1)
    right_header: null,
    right_top: null, // bottom sidebar right (toolbar 2)
    right_bottom: null,
  });
  const [activeProject, setActiveProject] = useState(null);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [activePointcloud, setActivePointcloud] = useState(null);
  const [activePole, setActivePole] = useState(null);
  const [potreeMode, setPotreeMode] = useState(false);
  const [markerPlacement, setMarkerPlacement] = useState([]);
  const [groundCollectMode, setGroundCollectMode] = useState(false);

  const store = {
    poles: [poles, setPoles],
    projects: [availableProjects, setAvailableProjects],
    layout: [layout, setLayout],
    sideBarModules: [sideBarModules, setSidebarModules],
    potreeViewer: [potreeViewer, setPotreeViewer],
    activeProject: [activeProject, setActiveProject],
    availableProjects: [availableProjects, setAvailableProjects],
    theStage: [theStage, setTheStage],
    activePointcloud: [activePointcloud, setActivePointcloud],
    activePole: [activePole, setActivePole],
    dropdownData: [dropdownData, setDropdownData],
    measurementMap: [measurementMap, setMeasureMentMap],
    potreeMode: [potreeMode, setPotreeMode],
    markerPlacement: [markerPlacement, setMarkerPlacement],
    groundCollectMode: [groundCollectMode, setGroundCollectMode],
    imageId: [imageId, setImageId],
    map: [map, setMap]
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider;