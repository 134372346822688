
/**
 * takes 
 * @constructor
 * @param {response} newRecord - The response from a newly created record
 * @param {string} labelRoot - The root label without the id antenna_top for example
 * @param {string} tempTrackingId - The temporary label that was being used to track the measurement before a record id existed: antenna_top_${newWirelessId} for example.
 * @param {state } measurementMap - the state of the measurementMap. uses react useContext
* @param {state setter} setMeasurementMap - the setMeasurementMap setter function to set the state of the measurement map int he StoreContext. uses react useContext.
 */
const remapMeasurementWithRecord = (newRecord, labelRoot, tempTrackingId, measurementMap, setMeasurementMap) => {
    // this will take the temporary random string and replace it with the newly created record id so we can further edit measurements after saving.
    if (Object.keys(measurementMap).includes(`${labelRoot}_${tempTrackingId}`)) { 
      setMeasurementMap((prevMap) => { 
        const newMap = { ...prevMap };
        newMap[`${labelRoot}_${newRecord.insert_id}`] = prevMap[`${labelRoot}_${tempTrackingId}`];
        delete newMap[`${labelRoot}_${tempTrackingId}`];
        return newMap;
      });
    }
  };

export default remapMeasurementWithRecord;