/* eslint-disable */
import React, { useState, useContext } from "react";
import { StoreContext } from '../../Contexts';
import { DistributionApi } from "../../api";
import {
  IconButton,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Alert,
  LinearProgress
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";
import withAttachmentManager from "./AttachmentManagerHOC";
import { StepOption } from "../../Enums";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";
import { attachmentMeasurementTypeObjects } from "../../type-objects";
import { measurementTools } from "../../utils/reusable-functions";

const Distribution = (props) => {
  const [formData, setFormData] = useState({
    type_id: null,
    existing: '',
    proposed: ''
  })
  const appContext = useContext(StoreContext);
  const [activePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [dropdownData] = appContext.dropdownData;
  const [measurementMap] = appContext.measurementMap;
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleDropdownChange = (key, e) => {
    // will cancel measurement from previous attempt if you change types.
    // this will prevent from having lingering dots in potree if the user starts measuring something else.  
    // added this handleClearMeasurements function in the case that we want to clear measurements, but not go back to the start step. 
    props.handleClearMeasurements(attachmentMeasurementTypeObjects.distributionMeasurementTypes);
    props.handleClearMeasurements(attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes);
    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const saveMeasurements = async () => {
    setLoading(true);
    // we start off with no validation errors. 
    setValidationErrors([]);
    // firs check validation
    let formDataCollected = {};
    const errors = [];
    // handle validations here first. 
    // the 1000 hack.
    if (formData.type_id < 1000) {
      // treat like a normal distribution with existing and proposed. 
      formDataCollected = {
        "existing": null,
        "proposed": null,
        "existing_coord": null,
        "proposed_coord": null,
        "distribution_type_id": formData.type_id,
        "created_by": activePole.reviewer,
        "updated_by": activePole.reviewer,
        "pole_id": activePole.id
      }

      attachmentMeasurementTypeObjects.distributionMeasurementTypes.forEach(type => {
        if (Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`)) {
          const measurement = measurementMap[`${type.id}_${props.newAttachmentId}`];
          formDataCollected[type.title] = type.getMeasurement(measurement, measurementMap.pole_base);
          formDataCollected[`${type.title}_coord`] = vec3ToSerializedString(measurement.points[0].position);
        }
      })

      if (!attachmentMeasurementTypeObjects.distributionMeasurementTypes
        .some(type => Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`))) {
        props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
      }
    }
    // the 1000 hack.// for complex distribution equipment. 
    if (formData.type_id > 1000) {
      // treat as a complex equipment with attached and bottom measurments. 
      // subtract 1000 from id to get the correct id. 
      const correctId = formData.type_id - 1000;
      formDataCollected = {
        // all the measurement values.
        "equipment_attached": null,
        "equipment_attached_coord": null,

        "equipment_bottom": null,
        "equipment_bottom_coord": null,

        "equipment_attached_proposed": null,
        "equipment_attached_proposed_coord": null,

        "equipment_bottom_proposed": null,
        "equipment_bottom_proposed_coord": null,

        "equipment_type_id": correctId,

        "created_by": activePole.reviewer,
        "updated_by": activePole.reviewer,
        "pole_id": activePole.id
      }
      attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes.forEach(type => {
        if (Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`)) {
          const measurement = measurementMap[`${type.id}_${props.newAttachmentId}`];
          formDataCollected[type.title] = measurementTools.getHeight(measurement, measurementMap.pole_base);
          formDataCollected[`${type.title}_coord`] = vec3ToSerializedString(measurement.points[0].position);
        }
      })

      if (!attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes
        .some(type => Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`))) {
        props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
      }
    }
    // the user must select a type. 
    if (!formDataCollected.distribution_type_id && !formDataCollected.equipment_type_id) {
      console.log(formDataCollected);
      // this does not do an alert. It just highlights the select input red. 
      // not every attachment manager will have a select box. Not sure how to abstract. 
      errors.push({ severty: 'error', message: 'no_type' });
    }

    setValidationErrors(errors);

    // if there are no validation errors then save. 
    if (!errors.length) {
      props.handleErrors(null);
      // save the measurements. 
      const response = formData.type_id > 1000
        ? await DistributionApi.upsertExtraDistributionEquipment(formDataCollected)
        : await DistributionApi.upsertDistribution(formDataCollected);
      // will remap the measurementMap to use the record id instead of the random temporary string. 
      await props.handleSaveMeasurements(response, attachmentMeasurementTypeObjects.distributionMeasurementTypes);
      await props.handleSaveMeasurements(response, attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes);
      setFormData({
        type_id: '',
        existing: '',
        proposed: ''
      });
    }
    setLoading(false);
  }

  if (props.step === StepOption.Start) {
    return (
      <div className="distribution-manager">
        <strong>
          Distribution
          <IconButton size="small" onClick={() => { props.handleStartNewAttachment(); }} sx={{ float: 'right' }} color="primary">
            <AddCircleOutlineOutlined />
          </IconButton>
        </strong>
        {activePole.distribution_lines.length ? (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  {attachmentMeasurementTypeObjects.distributionMeasurementTypes.map(type =>
                    <TableCell key={type.title} align="center">{type.title}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {activePole.distribution_lines.map((distribution, index) => (
                  <TableRow
                    key={distribution.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      #{index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {dropdownData['pole_distribution'].find((obj) => obj.id === parseInt(distribution.distribution_type_id)).label}
                    </TableCell>
                    {attachmentMeasurementTypeObjects.distributionMeasurementTypes.map(type =>
                      <TableCell key={`${type.id}_${distribution.id}`} align="left">{measurementMap[`${type.id}_${distribution.id}`]
                        ? type.getMeasurement(measurementMap[`${type.id}_${distribution.id}`], measurementMap.pole_base)
                        : 'n/a'}</TableCell>)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        {activePole.distribution_equipment.length > 0 &&
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  {attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes.map(type =>
                    <TableCell key={type.title} align="center">{type.title}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {activePole.distribution_equipment.map((distribution, index) => (
                  <TableRow
                    key={distribution.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row">
                      #{index + 1}
                    </TableCell>
                    <TableCell align="center">{dropdownData['pole_distribution_equip_typ']
                      .find((obj) => obj.id === parseInt(distribution.equipment_type_id)).type}
                    </TableCell>
                    {attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes.map(type =>
                      <TableCell key={`${type.id}_${distribution.id}`} align="left">{measurementMap[`${type.id}_${distribution.id}`]
                        ? type.getMeasurement(measurementMap[`${type.id}_${distribution.id}`], measurementMap.pole_base)
                        : 'n/a'}</TableCell>)}
                  </TableRow>))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </div>
    );
  }

  if (props.step === StepOption.Create) {
    return (
      <div className="distribution-manager">
        <strong>Distribution
          <ButtonGroup sx={{ float: "right" }}>
            <Button variant="outlined" size='small' onClick={saveMeasurements} disabled={loading}>
              Save
            </Button>
            <Button
              variant="outlined"
              size='small'
              color="error"
              onClick={() => {
                formData.type_id < 1000
                  ? props.handleCancelMeasurement(attachmentMeasurementTypeObjects.distributionMeasurementTypes)
                  : props.handleCancelMeasurement(attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes);
              }}
              disabled={loading}>
              Cancel
            </Button>
          </ButtonGroup>
        </strong>
        {validationErrors.map(error => error.message).includes('no_measurements') &&
          <Alert severity="error">You must make a measurement to save.</Alert>}
        <FormControl fullWidth margin="dense" error={validationErrors.map(error => error.message).includes('no_type')}>
          <InputLabel id="distribution-type-select-label">Distribution Type</InputLabel>
          <Select
            size='small'
            labelId="distribution-type-select-label"
            id="distribution-type-select"
            value={formData.type_id}
            label="Distribution Type"
            onChange={(e) => { handleDropdownChange('type_id', e) }}>
            {dropdownData['pole_distribution'].map((menuItem, index) => (
              <MenuItem key={index} value={menuItem.id} data-equipment="basic" >{menuItem.label}</MenuItem>
            ))}
            {/*
                here we add 1000 as a hack to differentiate the type of equipment. 
                I don't know why this mui react component does not allow data attributes props. Seems crazy to me. 
                will have to roll a custom select component if we want more complex functionality 
                since there is only one other type of equipment configuration the 1000 hack will work for now. 
              */}
            {dropdownData['pole_distribution_equip_typ'].map((menuItem, index) => (
              <MenuItem key={index + 1000} value={menuItem.id + 1000} >{menuItem.type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {formData.type_id < 1000 &&
          <>
            {attachmentMeasurementTypeObjects.distributionMeasurementTypes.map(type => {
              return (
                <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}`}
                  attachmentId={props.newAttachmentId}
                  type={type}
                  existingMeasurements={measurementMap}
                  handleSetPointMode={props.handleSetPointMode} />);
            })}
          </>
        }
        {formData.type_id > 1000 &&
          <>
            {attachmentMeasurementTypeObjects.extraEquipmentMeasurementTypes.map(type => {
              return (
                <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}`}
                  attachmentId={props.newAttachmentId}
                  type={type}
                  existingMeasurements={measurementMap}
                  handleSetPointMode={props.handleSetPointMode} />);
            })}
          </>
        }
        {loading && <LinearProgress />}
      </div>)
  };
}

const DistributionManager = withAttachmentManager(Distribution);

export default DistributionManager;