const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;
const fetchProjectList = async () => {
  // const headers = new Headers();
  // headers.append("authorizationToken", authorizationToken);
  const url = `${baseUrl}/get_collection_location_data`;
  return await fetch(url)
    .then(response => response.json())
    .then(data => { return data.body })
    .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
  fetchProjectList,
}