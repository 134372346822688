import { Outlet } from "react-router-dom";
import smallLogo from './static/bv-logo-small.png';
import Sidebar from './custom-components/Sidebar.js';
import React, { useContext } from 'react';
import {
  Container,
  Box,
  Toolbar,
  AppBar,
  Typography,
  IconButton,
  CssBaseline,
  styled
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { StoreContext } from "./Contexts";
import { LayoutOption } from "./Enums";

const defaultDrawerWidth = '20%';

const Main = styled(Container, { shouldForwardProp: (prop) => !['leftSidebarOpen', 'rightSidebarOpen'].includes(prop) })
  (({ theme, leftSidebarOpen, rightSidebarOpen }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${defaultDrawerWidth}`,
    ...(leftSidebarOpen && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    marginRight: `-${defaultDrawerWidth}`,
    ...(rightSidebarOpen && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }));

const BVAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['leftSidebarOpen', 'rightSidebarOpen'].includes(prop),
})(({ theme, leftSidebarOpen, rightSidebarOpen }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(leftSidebarOpen && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(rightSidebarOpen && {
    marginLeft: `calc(100% - ${defaultDrawerWidth})`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Layout() {
  const appContext = useContext(StoreContext);
  const [layout] = appContext.layout;
  const [leftSidebarOpen, setLeftSidebarOpen] = React.useState(true);
  const [rightSidebarOpen, setRightSidebarOpen] = React.useState(true);
  const handleLeftSidebarOpen = () => {
    setLeftSidebarOpen(true);
  };
  const handleRightSidebarOpen = () => {
    setRightSidebarOpen(true);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <BVAppBar position="fixed" leftSidebarOpen={leftSidebarOpen} rightSidebarOpen={rightSidebarOpen}>
        <Toolbar>
          {[LayoutOption.SideBarLeft, LayoutOption.SideBars].includes(layout) &&
            <IconButton
              color="inherit"
              aria-label="open left sidebar"
              onClick={handleLeftSidebarOpen}
              edge="start"
              sx={{ mr: 2, ...(leftSidebarOpen && { display: 'none' }) }}
            >
              <ChevronRightIcon />
            </IconButton>}
          <Box
            component="img"
            sx={{
              display: { xs: 'none', md: 'flex' },
              mr: 1,
              height: 40,
            }}
            alt="BV logo."
            src={smallLogo}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            GeoAI
          </Typography>
          {[LayoutOption.SideBarRight, LayoutOption.SideBars].includes(layout) &&
            <IconButton
              color="inherit"
              aria-label="open right sidebar"
              onClick={handleRightSidebarOpen}
              sx={{ mr: 2, ...(rightSidebarOpen && { display: 'none' }) }}
            >
              <ChevronLeftIcon />
            </IconButton>}
        </Toolbar>
      </BVAppBar>
      {[LayoutOption.SideBarLeft, LayoutOption.SideBars].includes(layout) &&
        <Sidebar open={leftSidebarOpen}
          onClose={setLeftSidebarOpen}
          side='left'
          drawerWidth={defaultDrawerWidth} />
      }
      <Main leftSidebarOpen={leftSidebarOpen}
        rightSidebarOpen={rightSidebarOpen}
        className="main"
        disableGutters={true}
        maxWidth={false}>
        <Outlet />
      </Main>
      {[LayoutOption.SideBarRight, LayoutOption.SideBars].includes(layout) &&
        <Sidebar
          open={rightSidebarOpen}
          onClose={setRightSidebarOpen}
          side='right'
          drawerWidth={defaultDrawerWidth} />
      }
    </Box>

  )
}