/* eslint-disable */

// will load on map-one route. 
import React, { useState, useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { StoreContext } from '../Contexts';
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";


const DumyModules = () => {
  const appContext = useContext(StoreContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;

  useEffect(() => {
    setLayout(LayoutOption.SideBars);
    setTheStage(InteractiveSection.Dummy);
    setSidebarModules({
      left_top: InteractiveSection.Dummy, // top sidebar left (toolbar 1)
      left_bottom: InteractiveSection.Dummy, //bottom sidebar left (toolbar 1)
      right_top: InteractiveSection.Dummy, // bottom sidebar right (toolbar 2)
      right_bottom: InteractiveSection.Dummy,
    });
  }, []);

  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default DumyModules;
