
import { createContext, useState } from 'react'

export const PositionContext = createContext(null)

export default function PositionProvider({ children }) {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [heading, setHeading] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [selectedPointCloudId, setSelectedPointCloudId] = useState(null);

    const position = {
        latitude: [latitude, setLatitude],
        longitude: [longitude, setLongitude],
        heading: [heading, setHeading],
        zoom: [zoom, setZoom],
        selectedPointCloudId: [selectedPointCloudId, setSelectedPointCloudId]
    }

    return <PositionContext.Provider value={position}>{children}</PositionContext.Provider>
}