/* eslint-disable */
import React, { useState, useContext } from "react";
import { StoreContext } from '../../Contexts';
import { CommsApi } from "../../api";
import {
  IconButton,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  LinearProgress
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";
import withAttachmentManager from "./AttachmentManagerHOC";
import { StepOption } from "../../Enums";
import { attachmentMeasurementTypeObjects } from "../../type-objects";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";

const WirelineComms = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type_id: '',
    existing: '',
    proposed: ''
  })
  const appContext = useContext(StoreContext);
  const [activePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [dropdownData] = appContext.dropdownData;
  const [measurementMap] = appContext.measurementMap;
  const [validationErrors, setValidationErrors] = useState([]);

  const saveMeasurements = async () => {
    setLoading(true);
    const errors = [];
    const formDataCollected = {
      "existing": null,
      "proposed": null,
      "existing_coord": null,
      "proposed_coord": null,
      "comms_type_id": formData.type_id,
      "comms_owner_id": "1", // I don't understand what this is either. There is not dropdown for this.
      "created_by": activePole.reviewer,
      "updated_by": activePole.reviewer,
      "pole_id": activePole.id,
      "comms_height": "1" // I don't understand what this is. // is this different than existing and proposed? 
    }

    attachmentMeasurementTypeObjects.wirelineMeasurementTypes.forEach(type => {
      if (Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`)) {
        const measurement = measurementMap[`${type.id}_${props.newAttachmentId}`];
        formDataCollected[type.title] = type.getMeasurement(measurement, measurementMap.pole_base);
        formDataCollected[`${type.title}_coord`] = vec3ToSerializedString(measurement.points[0].position);
      }
    })

    if (!attachmentMeasurementTypeObjects.wirelineMeasurementTypes.some(type => formDataCollected[type.title] !== null)) {
      props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
    }

    // the user must select a type. 
    if (!formDataCollected.comms_type_id) {
      console.log(formDataCollected);
      // this does not do an alert. It just highlights the select input red. 
      // not every attachment manager will have a select box. Not sure how to abstract. 
      errors.push({ severty: 'error', message: 'no_type' });
    }

    setValidationErrors(errors);
    // if there are no validation errors then save. 
    if (!errors.length) {
      props.handleErrors(null);
      // save the measurements. 
      const response = await CommsApi.upsertWirelineComm(formDataCollected);
      // will remap the measurementMap to use the record id instead of the random temporary string. 
      await props.handleSaveMeasurements(response, attachmentMeasurementTypeObjects.wirelessMeasurementTypes);
      setFormData({
        type_id: '',
        existing: '',
        proposed: ''
      });
      props.setStep(StepOption.Start);
    }
    setLoading(false);
  }

  if (props.step === StepOption.Start) {
    return (
      <div className="comms-manager">
        <strong>
          Wireline
          <IconButton size="small" onClick={() => { props.handleStartNewAttachment(); }} sx={{ float: 'right' }} color="primary">
            <AddCircleOutlineOutlined />
          </IconButton>
        </strong>
        {activePole.wireline_comms.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Type</TableCell>
                  {attachmentMeasurementTypeObjects.wirelineMeasurementTypes.map(type =>
                    <TableCell align="center">{type.title}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {activePole.wireline_comms.map((comm, index) => (
                  <TableRow
                    key={comm.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      #{index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {dropdownData['pole_attachment_type'].find((obj) => obj.id === parseInt(comm.comms_type_id)).attachment_type}
                    </TableCell>
                    {attachmentMeasurementTypeObjects.wirelineMeasurementTypes.map(type =>
                      <TableCell key={`${type.id}_${comm.id}`} align="center">
                        {measurementMap[`${type.id}_${comm.id}`]
                          ? type.getMeasurement(measurementMap[`${type.id}_${comm.id}`], measurementMap.pole_base)
                          : 'n/a'}
                      </TableCell>)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>)}
      </div>
    );
  }

  if (props.step === StepOption.Create) {
    return (
      <div className="comms-manager">
        <strong>Wireline
          <ButtonGroup sx={{ float: "right" }}>
            <Button variant="outlined" size='small' onClick={saveMeasurements} disabled={loading}>
              Save
            </Button>
            <Button variant="outlined"
              size='small'
              color="error"
              onClick={() => { props.handleCancelMeasurement(attachmentMeasurementTypeObjects.wirelineMeasurementTypes); }}
              disabled={loading}>
              Cancel
            </Button>
          </ButtonGroup>
        </strong>
        <FormControl fullWidth margin="dense" error={validationErrors.includes('no_type')}>
          <InputLabel id="attachment-type-select-label">Attachment Type</InputLabel>
          <Select
            labelId="attachment-type-select-label"
            id="attachment-type-select"
            value={formData.type_id}
            label="Comms Type"
            onChange={(e) => { props.handleDropdownChange('type_id', e, setFormData) }}>
            {dropdownData['pole_attachment_type'].map((menuItem, index) => (
              <MenuItem key={index} value={menuItem.id}>{menuItem.attachment_type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {attachmentMeasurementTypeObjects.wirelineMeasurementTypes.map(type => {
          return (
            <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}`}
              attachmentId={props.newAttachmentId}
              type={type}
              existingMeasurements={measurementMap}
              handleSetPointMode={props.handleSetPointMode} />);
        })}
        {loading && <LinearProgress />}
      </div>
    )
  }
};

const WirelineCommsManager = withAttachmentManager(WirelineComms);

export default WirelineCommsManager;