/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useContext, useState, useEffect, forwardRef } from "react";
import { PointCloudApi, PoleApi } from '../../api';
import { LayoutOption } from '../../Enums';
import { measurementTools } from '../../utils/reusable-functions';
import {
  IconButton,
  ButtonGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  LinearProgress,
  Container,
  Backdrop,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { openPointCloud } from '../../utils/PointCloudUtils';
import { useNavigate } from 'react-router-dom';

const calculations = (pole) => {
  const poleBase = JSON.parse(pole.pole_base);

  const result = {
    pole_agl: null,
    pole_base: measurementTools.metersToFeet(poleBase.z)
  }

  if (pole.pole_agl) {
    const poleAgl = JSON.parse(pole.pole_agl);
    const poleAglDelta = poleAgl.z - poleBase.z;
    const poleAglFeet = measurementTools.metersToFeet(poleAglDelta);
    result.pole_agl = poleAglFeet;
  }

  return result;
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PoleInfoPanel() {
  const navigate = useNavigate();
  const appContext = useContext(StoreContext);
  const [activePole, setActivePole] = appContext.activePole;
  const [activeProject] = appContext.activeProject;
  const [theStage] = appContext.theStage;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [layout, setLayout] = appContext.layout;
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [activePointcloud, setActivePointCloud] = appContext.activePointcloud;
  const [modalOpen, setModalOpen] = useState(false);

  const getDropdownData = async () => {
    if (dropdownData === null) {
      const response = await PoleApi.fetchPoleDropdownValues();
      setDropdownData(response);
    }
  }

  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const closePanel = () => {
    setActivePole(null);
    setSidebarModules(prevState => ({
      ...prevState,
      right_top: null
    }));
    setLayout(LayoutOption.FullPage);
  }

  const deletePole = async () => {
    setDeleting(true);
    await PoleApi.deletePoleById(activePole.id);
    setLoading(false);
    closePanel();
  }

  const editPole = async () => {
    setLoading(true);
    const selectedPointCloudData = await PointCloudApi
      .fetchPointCloudByGPSLocation(activeProject.collection_name, activePole.lat, activePole.lng);
    await openPointCloud(setActivePointCloud,
      theStage,
      navigate,
      selectedPointCloudData[0],
      selectedPointCloudData[0].central_lat,
      selectedPointCloudData[0].central_lng,
      activePole.id);
    setLoading(false);
  }

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (activePole && Object.keys(activePole).length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [activePole]);

  if (!activePole) {
    return (
      <div className="active-pole-info-panel">
        {loading && <LinearProgress />}
        <IconButton onClick={closePanel}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  } else {
    return (
      <Container className="active-pole-info-panel">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <h3>Pole Id: #{activePole.id}
          <IconButton onClick={closePanel} sx={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </h3>
        <section className="measurements-display-section">
          <p>{activePole.date_created ? `Date Created: ${activePole.date_created}` : ''}</p>
          <p>{activePole.date_updated ? `Date Updated: ${activePole.date_updated}` : ''}</p>
          <p>{activePole.pole_owner ? `Pole Owner: ${activePole.pole_owner}` : ''}</p>
          <p>{activePole.pole_tag_1 ? `Pole Tag 1: ${activePole.pole_tag_1}` : ''}</p>
          <p>{activePole.pole_tag_2 ? `Pole Tag 2: ${activePole.pole_tag_2}` : ''}</p>
          <p>{activePole.pole_tag_3 ? `Pole Tag 3: ${activePole.pole_tag_3}` : ''}</p>
          <p>{activePole.lat ? `Lat: ${activePole.lat}` : ''}</p>
          <p>{activePole.lng ? `Lng: ${activePole.lng}` : ''}</p>
          <p>{activePole.reviewer ? `Reviewer: ${activePole.reviewer}` : ''}</p>
          <p>{activePole.pole_field_notes ? `Field Notes: ${activePole.pole_field_notes}` : ''}</p>
        </section>

        <h3>Measurements</h3>
        <section className="measurements-display-section">
          <p>{activePole.pole_base ? `Pole Base Elevation: ${calculations(activePole).pole_base}ft` : ''}</p>
          <p>{activePole.pole_length ? `Pole Length: ${activePole.pole_length}ft` : ''}</p>
          <p>{activePole.pole_agl ? `Pole AGL: ${calculations(activePole).pole_agl}` : ''}</p>
        </section>
        <h3>Attachments</h3>
        <section className="measurements-display-section">
          <h4>Transmission</h4>
          {activePole.transmission_lines.map((transmission) => (
            <div className="attachment-list-item">
              <p>{transmission.existing !== null ? `Existing: ${transmission.existing}ft` : ''}</p>
              <p>{transmission.proposed !== null ? `Proposed:${transmission.proposed}ft` : ''}</p>
              <p>{transmission.type !== null ? `Type: Transmission` : ''}</p>
            </div>
          ))}
        </section>
        <section className="measurements-display-section">
          <h4>Distribution</h4>

          {dropdownData && activePole.distribution_lines.map((distribution) => (
            <div className="attachment-list-item">
              <p>{distribution.existing ? `Existing: ${distribution.existing}ft` : ''}</p>
              <p>{distribution.proposed ? `Proposed:${distribution.proposed}ft` : ''}</p>
              <p>
                {distribution.distribution_type_id ?
                  `Type: ${dropdownData.pole_distribution.find((obj) => obj.id === parseInt(distribution.distribution_type_id)).label}`
                  : ''}
              </p>
              <p>{distribution.equipment_attached ? `Attached: ${distribution.equipment_attached}ft` : ''}</p>
              <p>{distribution.equipment_bottom ? `Bottom: ${distribution.equipment_bottom}ft` : ''}</p>
              <p>{distribution.equipment_attached_proposed ? `Attached Proposed: ${distribution.equipment_attached_proposed}ft` : ''}</p>
              <p>{distribution.equipment_bottom_proposed ? `Bottom Proposed: ${distribution.equipment_bottom_proposed}ft` : ''}</p>
              <p>
                {distribution.equipment_type_id ?
                  `Type: ${dropdownData.pole_distribution_equip_typ.find((obj) => obj.id === parseInt(distribution.equipment_type_id)).type}`
                  : ''}
              </p>
            </div>
          ))}
        </section>
        <section className="measurements-display-section">
          <h4>Wireless</h4>
          {activePole.wireless_comms.map((comm) => (
            <div className="attachment-list-item">
              <p>{comm.antenna_top ? `Antenna Top: ${comm.antenna_top}ft` : ''}</p>
              <p>{comm.antenna_attached ? `Antenna Attached:${comm.antenna_attached}ft` : ''}</p>
              <p>{comm.antenna_bottom ? `Antenna Bottom:${comm.antenna_bottom}ft` : ''}</p>

              <p>{comm.antenna_top_proposed !== '' ? `Antenna Top Proposed: ${comm.antenna_top_proposed}ft` : ''}</p>
              <p>{comm.antenna_attached_proposed !== '' ? `Antenna Attached Proposed:${comm.antenna_attached_proposed}ft` : ''}</p>
              <p>{comm.antenna_bottom_proposed !== '' ? `Antenna Bottom Proposed:${comm.antenna_bottom_proposed}ft` : ''}</p>
            </div>
          ))}
        </section>
        <section className="measurements-display-section">
          <h4>Wireline</h4>
          {dropdownData && activePole.wireline_comms.map((comm) => (
            <div key={comm.id} className="attachment-list-item">
              <p>{comm.existing !== null ? `Existing: ${comm.existing}ft` : ''}</p>
              <p>{comm.proposed !== null ? `Proposed:${comm.proposed}ft` : ''}</p>
              <p>
                {dropdownData !== null && comm.comms_type_id ?
                  `Type: ${dropdownData['pole_attachment_type'].find((obj) => obj.id === parseInt(comm.comms_type_id)).attachment_type}`
                  : ''}
              </p>
            </div>
          ))}
        </section>
        <ButtonGroup>
          <Button variant="outlined" color="primary" onClick={editPole}>Edit</Button>
          <Button variant="outlined" color="error" onClick={openModal}>Delete</Button>
        </ButtonGroup>
        <Dialog
          open={modalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModal}
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle>{"Delete This Pole?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Pole ID #${activePole.id}`}
              Are you sure you want to delete this pole? All associated mesurements will also be deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup>
              <Button variant="outlined" disabled={loading} color='primary' onClick={closeModal}>Cancel</Button>
              <LoadingButton variant="outlined" loading={deleting} color="error" onClick={deletePole}>Delete</LoadingButton>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default PoleInfoPanel;