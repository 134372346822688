/* eslint-disable */
import React, { useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { PositionContext, StoreContext } from '../Contexts';
import { useLocation } from "react-router-dom";
import { PointCloudApi, PoleApi, ProjectApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";
import { Vector3 } from "three";
import {
  distributionMeasurementTypes,
  extraEquipmentMeasurementTypes,
  transmissionMeasurementTypes,
  wirelessMeasurementTypes,
  wirelineMeasurementTypes
} from "../type-objects/AttachementMeasurementTypeObjects";
import { guyingMeasurementsObjects } from "../type-objects";

const PointcloudOne = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collection_name = queryParams.get("collection_name");
  const query_latitude = queryParams.get('lat');
  const query_longitude = queryParams.get('lng');
  const pole_id = queryParams.get("pole_id");
  const image_id = queryParams.get("image_id");
  const appContext = useContext(StoreContext);
  const positionContext = useContext(PositionContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [activePole, setActivePole] = appContext.activePole;
  const [imageId, setImageId] = appContext.imageId;
  const [potreeViewer] = appContext.potreeViewer;
  const [measurementMap, setMeasurementMap] = appContext.measurementMap;
  const [latitude, setLatitude] = positionContext.latitude;
  const [longitude, setLongitude] = positionContext.longitude;

  const fetchData = async () => {
    const selectedPointCloudData = (await PointCloudApi
      .fetchPointCloudByGPSLocation(collection_name, query_latitude, query_longitude))[0];
    if (pole_id) {
      let pole = await PoleApi.getPoleWithAttachementsById(pole_id);
      const sceneData = JSON.parse(pole.scene_data);
      setActivePole(pole);
      setActivePointcloud({
        collection_set: selectedPointCloudData.collection_set,
        collection_id: selectedPointCloudData.collection_id,
        collection_name,
        crs: sceneData.pc.crs,
        pointcloud_id: sceneData.pc.pointcloud_id
      });
    }
    else {
      setActivePointcloud({
        collection_set: selectedPointCloudData.collection_set,
        collection_id: selectedPointCloudData.collection_id,
        collection_name,
        crs: selectedPointCloudData.crs,
        pointcloud_id: selectedPointCloudData.id
      });
    }
    if (image_id) {
      setImageId(image_id);
    }
    setLatitude(query_latitude);
    setLongitude(query_longitude);
  }

  const getActiveProject = async (collectionName) => {
    // if activeProject is not set then set it. we need the project id for certain things. 
    if (activeProject === null) {
      const projectList = await ProjectApi.fetchProjectList();
      // find the active project and set it to the state so we can use it in future interactions.
      const theActiveProject = projectList.find(project => project.collection_name === collectionName);
      setActiveProject(theActiveProject);
      return theActiveProject;
    }
    return null;
  }
  const loadMeasurement = (measurementLabel, coords) => {
    const Potree = window.Potree;
    let measure = new Potree.Measure();
    measure.name = measurementLabel;
    measure.showDistances = false;
    measure.showCoordinates = false;
    measure.maxMarkers = 1;
    measure.addMarker(new Vector3(coords.x, coords.y, coords.z));
    potreeViewer.scene.addMeasurement(measure);
    // need to set the map intially since there are no drop events happening here. 
    setMeasurementMap((prevMap) => {
      return {
        ...prevMap,
        [measurementLabel]: measure
      }
    });
    measure.addEventListener('marker_dropped', (event) => {
      setMeasurementMap((prevMap) => {
        return {
          ...prevMap,
          [measurementLabel]: measure
        }
      });
    });

  }

  const handleAttachmentMeasurments = (labelList, attachment) => {
    labelList.forEach((label) => {
      // need to look at fixing how these are returned, or initially save when there is no value. 
      // inconsistent so need to look at length. in some cases empty coord is saved as "None" in others just as empty string ""
      // WE DO KNOW that a field with an actual coord saved will for sure be longer than 4 characters.
      let coord_label_title = label.title.includes('_coord') ? label.title : `${label.title}_coord`;
      if (["x", "y", "z"].every(el => attachment[coord_label_title]?.includes(el))) {
        try {
          const coords = JSON.parse(attachment[coord_label_title]);
          if (coords) { loadMeasurement(`${label.id}_${attachment.id}`, coords); }
        } catch (e) {
          console.log(`Invalid JSON [${coord_label_title}: ${attachment[coord_label_title]}]`);
        }
      }
    });
  }

  const loadAttachments = () => {
    activePole.transmission_lines.forEach((attachment) => {
      handleAttachmentMeasurments(transmissionMeasurementTypes, attachment);
    });
    activePole.wireless_comms.forEach((attachment) => {
      handleAttachmentMeasurments(wirelessMeasurementTypes, attachment);
    });
    activePole.wireline_comms.forEach((attachment) => {
      handleAttachmentMeasurments(wirelineMeasurementTypes, attachment);
    });
    activePole.distribution_lines.forEach((attachment) => {
      handleAttachmentMeasurments(distributionMeasurementTypes, attachment);
    });
    activePole.distribution_equipment.forEach((attachment) => {
      handleAttachmentMeasurments(extraEquipmentMeasurementTypes, attachment);
    });
    activePole.anchors.forEach((anchor) => {
      handleAttachmentMeasurments(guyingMeasurementsObjects.guyingMeasurementTypes, anchor);
      anchor.guy.forEach((down_guy) => {
        handleAttachmentMeasurments(guyingMeasurementsObjects.downGuyingMeasurementTypes, down_guy);
      })
      anchor.guy_sidewalkbrace.forEach((sidewalk_brace) => {
        handleAttachmentMeasurments(guyingMeasurementsObjects.sidewalkBraceMeasurementTypes, sidewalk_brace);
      })
    });
  }

  const loadPoleIntoViewer = () => {
    potreeViewer.scene.removeAllMeasurements();
    if (activePole.pole_base) {
      loadMeasurement(
        'pole_base',
        JSON.parse(activePole.pole_base),
        potreeViewer
      );
    }
    if (activePole.pole_agl) {
      loadMeasurement(
        'pole_agl',
        JSON.parse(activePole.pole_agl),
        potreeViewer
      );
    }
    loadAttachments();
    // potreeViewer.scene.view.lookAt(new Vector3(activePole.pole_base.x, activePole.pole_base.y, activePole.pole_base.z));
  }

  useEffect(() => {
    setLayout(LayoutOption.SideBars);;
    setTheStage(InteractiveSection.PointCloud);
    setSidebarModules({
      left_header: InteractiveSection.MeasureTools,
      left_top: InteractiveSection.Panorama,
      left_bottom: InteractiveSection.Map,
      right_header: InteractiveSection.AddPoleButton,
      right_top: InteractiveSection.Measurements,
      right_bottom: null
    });
    getActiveProject(collection_name);
    fetchData();
  }, []);

  useEffect(() => {
    if (potreeViewer && activePole) {
      loadPoleIntoViewer(activePole);
    }
  }, [activePole, potreeViewer]);

  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default PointcloudOne;
