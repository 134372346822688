const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;

const getWirelineCommsByPoleId = async (pole_id) => {
    const url = `${baseUrl}/get_pole_comms_wireline_data_by_pole_id?pole_id=${pole_id}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const upsertWirelineComm = async (data) => {
    const url = `${baseUrl}/upsert_pole_comms_wireline_data`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getWirelessCommsByPoleId = async (pole_id) => {
    const url = `${baseUrl}/get_pole_comms_wireless_data_by_pole_id?pole_id=${pole_id}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const upsertWirelessComm = async (data) => {
    const url = `${baseUrl}/upsert_pole_comms_wireless_data`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}
export {
    getWirelineCommsByPoleId,
    upsertWirelineComm,
    getWirelessCommsByPoleId,
    upsertWirelessComm
}