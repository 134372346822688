/* eslint-disable */
// import { StoreContext } from '../../utils/store';
import React, { useState, useContext } from "react";
import {
  Button,
  Container,
  Divider,
  Menu,
  MenuItem
} from '@mui/material';
import { StoreContext } from '../../Contexts';

const LengthUnits = {
  METER: { code: 'm', unitspermeter: 1.0 },
  FEET: { code: 'ft', unitspermeter: 3.28084 },
  INCH: { code: '\u2033', unitspermeter: 39.3701 }
};

const measurementTools = ['Point', 'Distance', 'Height', 'Circle', 'Azimuth', 'Area', 'Angle']
function MeasureTools() {
  const appContext = useContext(StoreContext);
  const [measurementToolsMap, setMeasurementToolsMap] = useState([]);
  const [selected, setSelected] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [potreeViewer] = appContext.potreeViewer;

  const handleMeasureEvents = (measurement) => {
    measurement.addEventListener('marker_dropped', (event) => {
      console.log(event.target);
      setMeasurementToolsMap((prevMap) => {
        return {
          ...prevMap,
          [event.target.uuid]: event.target
        }
      });
      setSelected(null);
    });
  }

  const startMeasure = (tool) => {
    potreeViewer.lengthUnitDisplay = LengthUnits.FEET;
    setSelected(tool);
    switch (tool) {
      case 'Point':
        measurePoint();
        break;
      case 'Distance':
        measureDistance();
        break;
      case 'Height':
        measureHeight();
        break;
      case 'Circle':
        measureCircle();
        break;
      case 'Azimuth':
        measureAzimuth();
        break;
      case 'Area':
        measureArea();
        break;
      case 'Angle':
        measureAngle();
        break;
    }
    handleClose();
  }

  const measurePoint = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: true,
      showArea: false,
      closed: true,
      maxMarkers: 1,
      name: 'Point'
    });
    // will handle the mapping of the measurement being made. 
    // we just need this for deleting later
    // we can't do a full measurement clear because we only want to clear
    // measurements made with the  tools 
    // a full clear would also wipe out the measurements made from the MeasurementSchema.js component
    handleMeasureEvents(measurement);
  }

  const measureDistance = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: false,
      closed: false,
      name: 'Distance'
    });
    handleMeasureEvents(measurement);
  }

  const measureHeight = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
      name: 'Height'
    });
    handleMeasureEvents(measurement);
  }

  const measureCircle = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: false,
      showArea: false,
      showCircle: true,
      showEdges: false,
      closed: false,
      maxMarkers: 3,
      name: 'Circle'
    });
    handleMeasureEvents(measurement);

  }

  const measureAzimuth = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: true,
      showHeight: false,
      showArea: false,
      showCircle: false,
      showEdges: false,
      showAzimuth: true,
      closed: false,
      maxMarkers: 2,
      name: 'Azimuth'
    });
    handleMeasureEvents(measurement);
  }

  const measureArea = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: true,
      closed: true,
      name: 'Area'
    });
    handleMeasureEvents(measurement);
  }

  const measureAngle = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: true,
      showArea: false,
      closed: true,
      maxMarkers: 3,
      name: 'Angle'
    });
    handleMeasureEvents(measurement);
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Button
        size="small"
        variant="contained"
        id="open-tools-button"
        aria-controls={open ? 'measure-tools-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {selected ?? 'Measure Tools'}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {measurementTools.map((option, index) => (
          <MenuItem
            key={option}
            selected={selected === option}
            onClick={() => { startMeasure(option); }}
            size="small">
            {option}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem variant="outlined"
          onClick={() => { setSelected(null); potreeViewer.scene.removeAllMeasurements(); }}
          size="small">
          Clear Measurements
        </MenuItem>
      </Menu>
    </Container>
  );
}

export default MeasureTools;