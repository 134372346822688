/* eslint-disable */
// will load on map-one route. 
import React, { useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { StoreContext } from '../Contexts';
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";


const MapOne = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project-id");
  const appContext = useContext(StoreContext);
  const [layout, setLayout] = appContext.layout;
  const [theStage, setTheStage] = appContext.theStage;
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;

  const fetchDataIfNeeded = async () => {
    if (!availableProjects.length) {
      const projectList = await ProjectApi.fetchProjectList();
      setAvailableProjects(projectList);
      const filteredArray = projectList.filter(obj => obj.collection_name === projectId);
      await setActiveProject(filteredArray[0]);
    }
  }

  useEffect(() => {
    fetchDataIfNeeded();
    setLayout(LayoutOption.FullPage);
    setTheStage(InteractiveSection.Map);
  }, []);

  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default MapOne;
