/* eslint-disable */
import React, { useState, useContext } from "react";
import { StoreContext } from '../../Contexts';
import { TransmissionApi } from "../../api";
import {
  IconButton,
  ButtonGroup,
  Button,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  LinearProgress
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
// do a search and replace in the whole codebase for this and replace with import
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";
import withAttachmentManager from "./AttachmentManagerHOC";
import { StepOption } from "../../Enums";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";
import { attachmentMeasurementTypeObjects } from "../../type-objects";

const Transmission = (props) => {
  const appContext = useContext(StoreContext);
  const [activePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [measurementMap] = appContext.measurementMap;
  const [loading, setLoading] = useState(false);

  const saveMeasurements = async () => {
    setLoading(true);
    const formData = {
      "existing": null,
      "proposed": null,
      "existing_coord": null,
      "proposed_coord": null,
      "type_id": "Transmission",
      "created_by": activePole.reviewer,
      "updated_by": activePole.reviewer,
      "pole_id": activePole.id
    }

    attachmentMeasurementTypeObjects.transmissionMeasurementTypes.forEach(type => {
      if (Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`)) {
        const measurement = measurementMap[`${type.id}_${props.newAttachmentId}`];
        formData[type.title] = type.getMeasurement(measurement, measurementMap.pole_base);
        formData[`${type.title}_coord`] = vec3ToSerializedString(measurement.points[0].position);
      }
    })

    if (!attachmentMeasurementTypeObjects.transmissionMeasurementTypes.some(type => formData[type.title] !== null)) {
      // validation code chunk.
      // stop the form from saving and show validation error. 
      props.handleErrors({ severty: 'error', message: 'You must make a measurement to save.' });
      setLoading(false);
    } else {
      props.handleErrors(null);
      // validation passes.
      // save the values. 
      const response = await TransmissionApi.upsertTransmission(formData);
      // the response contains the newly created record id 
      // we replace the random string id with the record id so we can edit it if we want.
      // we are tracking the potree measurment in react through the measurementMap.
      await props.handleSaveMeasurements(response, attachmentMeasurementTypeObjects.transmissionMeasurementTypes);
      setLoading(false);
    }
  }

  if (props.step === StepOption.Start) {
    return (
      <div className="transmission-manager">
        <strong>
          Transmission
          <IconButton size="small" onClick={() => { props.handleStartNewAttachment(); }} sx={{ float: 'right' }} color="primary">
            <AddCircleOutlineOutlined />
          </IconButton>
        </strong>
        {activePole.transmission_lines?.length > 0 &&
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  {attachmentMeasurementTypeObjects.transmissionMeasurementTypes.map(type =>
                    <TableCell key={`${type.id}_header`} align="center">{type.title}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {activePole.transmission_lines.map((transmission, index) => (
                  <TableRow
                    key={transmission.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      #{index + 1}
                    </TableCell>
                    {attachmentMeasurementTypeObjects.transmissionMeasurementTypes.map(type =>
                      <TableCell key={`${type.id}_${transmission.id}`} align="center">
                        {measurementMap[`${type.id}_${transmission.id}`]
                          ? type.getMeasurement(measurementMap[`${type.id}_${transmission.id}`], measurementMap.pole_base)
                          : 'n/a'}
                      </TableCell>)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
      </div>
    );
  }

  if (props.step === StepOption.ListData) {
    {
      activePole.transmission_lines.map((transmission) => (
        <div key={transmission.id} className="attachment-list-item">
          <p>{transmission.existing !== null ? `Existing: ${transmission.existing}ft` : ''}</p>
          <p>{transmission.proposed !== null ? `Proposed:${transmission.proposed}ft` : ''}</p>
          <p>{transmission.type !== null ? `Type: Transmission` : ''}</p>
        </div>
      ))
    }
  }

  if (props.step === StepOption.Create) {
    return (
      <div className="transmission-manager">
        <strong>Transmission</strong>
        <ButtonGroup sx={{ float: "right" }}>
          <Button variant="outlined" size='small' onClick={saveMeasurements} disabled={loading}>
            Save
          </Button>
          <Button variant="outlined"
            size='small'
            color="error"
            onClick={() => { props.handleCancelMeasurement(attachmentMeasurementTypeObjects.transmissionMeasurementTypes); }}
            disabled={loading}>
            Cancel
          </Button>
        </ButtonGroup>

        {attachmentMeasurementTypeObjects.transmissionMeasurementTypes.map(type => {
          return (
            <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}`}
              attachmentId={props.newAttachmentId}
              type={type}
              existingMeasurements={measurementMap}
              handleSetPointMode={props.handleSetPointMode} />);
        })}
        {loading && <LinearProgress />}
      </div>
    )
  }
};

const TransmissionManager = withAttachmentManager(Transmission);

export default TransmissionManager;