import { useContext, useEffect, } from "react";
import { PositionContext, StoreContext } from "../../../Contexts";
import { mapService } from "../../../services";
import { PanoramaApi, PointCloudApi } from "../../../api";
import { openPointCloud } from "../../../utils/PointCloudUtils";
import { useNavigate } from "react-router-dom";

const layerId = 'pointCloudArea';

const PointCloudPolyLineLayer = (
    { map,
        visible,
        layerRef,
        updateLayer }, ...props) => {
    const { Maps } = window.Microsoft;
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const positionContext = useContext(PositionContext);
    const [activeProject] = appContext.activeProject;
    const [theStage] = appContext.theStage;
    const [activePointcloud, setActivePointCloud] = appContext.activePointcloud;
    const [latitude, setLatitude] = positionContext.latitude;
    const [longitude, setLongitude] = positionContext.longitude;

    const updatePointCloudPolyline = async (mapRef) => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, mapRef, visible);
        layer.clear();
        let pointClouds = await PanoramaApi.fetchImageryByProject(activeProject.collection_name);
        if (pointClouds && pointClouds.length > 0) {
            var coordSets = [...new Set(pointClouds.map(pointCloud => pointCloud.collection_set))];
            for (let set of coordSets) {
                var coords = pointClouds
                    .filter(pointCloud => pointCloud.collection_set === set)
                    .sort((a, b) => a.id - b.id)
                    .map(pointCloud => new Maps.Location(pointCloud.Latitude_deg, pointCloud.Longitude_deg));
                var line = new Maps.Polyline(coords, {
                    strokeColor: new Maps.Color(50, 0, 255, 0),
                    strokeThickness: 5 * mapRef.getZoom()
                });
                layer.add(line);
                Maps.Events.addHandler(line, 'click', async (e) => {
                    const selectedPointCloudData = await PointCloudApi
                        .fetchPointCloudByGPSLocation(activeProject.collection_name, e.location.latitude, e.location.longitude);
                    setLatitude(e.location.latitude);
                    setLongitude(e.location.longitude);
                    await openPointCloud(setActivePointCloud, theStage, navigate, selectedPointCloudData[0], e.location.latitude, e.location.longitude);
                });
            }
        }
        updateLayer(layerId, layer)
    }

    useEffect(() => {
        const plotPointCloudPolyline = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await updatePointCloudPolyline(map);
            });
        }
        if (map) {
            plotPointCloudPolyline();
        }
    }, [map])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])
}

export default PointCloudPolyLineLayer;