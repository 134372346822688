const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;

const upsertGuyingAnchor = async (data) => {
    const url = `${baseUrl}/upsert_pole_guying_anchor_data`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const upsertDownGuy = async (data) => {
    const url = `${baseUrl}/upsert_pole_guying_data`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const upsertSidewalkBrace = async (data) => {
    const url = `${baseUrl}/upsert_pole_guying_sidewalkbrace_data`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    upsertDownGuy,
    upsertGuyingAnchor,
    upsertSidewalkBrace
}