/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useState, useContext } from "react";
import InteractiveSectionSelector from '../../utils/InteractiveSectionSelector';

function Stage() {
  const appContext = useContext(StoreContext); 
  const [theStage, setTheStage] = appContext.theStage;
  return (
    <InteractiveSectionSelector section={ theStage } />
  );
}

export default Stage;