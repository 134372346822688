import { useContext, useEffect } from "react";
import { StoreContext } from "../../../Contexts";
import { mapService } from "../../../services";
import { PanoramaUtils } from "../../../utils";
import { LayoutOption } from "../../../Enums";

const layerId = 'panoImagery';

const PanoramaImageLayer = (
    { map,
        visible,
        layerRef,
        updateLayer }, ...props) => {
    const { Maps } = window.Microsoft;
    const appContext = useContext(StoreContext);
    const [activeProject] = appContext.activeProject;
    const [layout] = appContext.layout;
    const [imageId, setImageId] = appContext.imageId;

    const updatePanoramaImages = async (mapRef) => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, mapRef, visible);
        layer.clear();
        let panoImagery = await PanoramaUtils.getPanoramas(activeProject.collection_name, mapService.getMapBounds(mapRef));
        panoImagery.forEach((image) => {
            let color = image.id === imageId ? 'aqua' : 'blue';
            let pushpin = new Maps.Pushpin({
                latitude: image.Latitude_deg,
                longitude: image.Longitude_deg,

            }, { color: color });
            pushpin.customData = image;
            layer.add(pushpin);

            Maps.Events.addHandler(pushpin, 'click', (e) => {
                setImageId(pushpin.customData.id);
                if (layout === LayoutOption.FullPage) {
                    window.open(`/pano-imagery-one?image_id=${pushpin.customData.id}`,
                        '_blank');
                }
            });
        });
        updateLayer(layerId, layer);
    }

    useEffect(() => {
        const plotPanoramaImages = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await updatePanoramaImages(map);
            });
            Maps.Events.addHandler(map, 'viewchangeend', async function () {
                await updatePanoramaImages(map);
            });
        }
        if (map) {
            plotPanoramaImages();
        }
    }, [map])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])
}

export default PanoramaImageLayer;