import React, { useEffect, useState } from "react";
import {
    FormControl,
} from '@mui/material';
import { SquareFoot, Calculate } from '@mui/icons-material';


const MeasurementInputControl = ({ attachmentId, type, existingMeasurements, handleSetPointMode }, ...props) => {
    const [value, setValue] = useState();
    const handleGetMarker = (measurementLabel, measurementMap) => {
        const marker = measurementMap[measurementLabel];
        return marker;
    }

    const getInputValue = () => {
        const newValue =
            Object.keys(existingMeasurements).includes(`${type.coord_id ?? type.id}_${attachmentId}`)
                ? type.getMeasurement(handleGetMarker(`${type.coord_id ?? type.id}_${attachmentId}`, existingMeasurements),
                    existingMeasurements.pole_base,
                    ...props)
                : '';
        setValue(newValue);
    }

    useEffect(() => { getInputValue() })

    return (
        <div className="measure-area" key={`${type.id}_${attachmentId}`}>
            {type.title || type.label}
            {type.calculated || props.disabled
                ? <Calculate disabled />
                : <SquareFoot
                    onClick={() => { handleSetPointMode(`${type.coord_id ?? type.id}_${attachmentId}`) }}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'rgb(79, 111, 255)'
                        }
                    }} />
            }
            <FormControl size="small" id={`${type.id}_${attachmentId}`}>
                {value}
            </FormControl>
        </div>);
}

export default MeasurementInputControl;