const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;

const upsertTransmission = async (data) => {
    const url = `${baseUrl}/upsert_pole_transmission_data`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify([data])
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getTransmissionListByPoleId = async (pole_id) => {
    const url = `${baseUrl}/get_pole_transmission_data_by_pole_id?pole_id=${pole_id}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    upsertTransmission,
    getTransmissionListByPoleId,
}