import { PanoramaApi, PointCloudApi } from "../api";
import { InteractiveSection } from "../Enums";

async function getPointCloudBounds(collection_name, bounds) {
    const pointcloudsInArea = await PointCloudApi.fetchPointCloudsByBounds(
        collection_name,
        bounds.northEast.latitude,
        bounds.northEast.longitude,
        bounds.southWest.latitude,
        bounds.southWest.longitude
    );
    return pointcloudsInArea.length < 200 ? pointcloudsInArea : [];
}

async function openPointCloud(setActivePointcloud, stage, navigate, selectedPointCloudData, lat, lng, poleId = null) {
    let nearestPanorama = await PanoramaApi
        .fetchNearestPanoImage(selectedPointCloudData.collection_name,
            selectedPointCloudData.collection_set,
            selectedPointCloudData.central_lat,
            selectedPointCloudData.central_lng);
    setActivePointcloud(
        {
            collection_set: selectedPointCloudData.collection_set,
            collection_id: selectedPointCloudData.collection_id,
            collection_name: selectedPointCloudData.collection_name,
            crs: selectedPointCloudData.crs,
            pointcloud_id: selectedPointCloudData.id
        });
    let url = `/pointcloud-one?collection_name=${selectedPointCloudData.collection_name}` +
        `&lat=${lat}` +
        `&lng=${lng}` +
        `&image_id=${nearestPanorama.id}` +
        (poleId ? `&pole_id=${poleId}` : '')
    stage === InteractiveSection.Map ? window.open(url, "_self") : navigate(url);
}

export {
    getPointCloudBounds,
    openPointCloud
}