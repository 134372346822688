
const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;
const getGroundCollectionDropdowns = async () => {
    const url = `${baseUrl}/get_ground_collection_request_dropdown`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const createGroundRequestCollection = async (data) => {
    const url = `${baseUrl}/create_ground_collection_request?authorizationToken=effffdb1-3f53-414d-a200-d3a6bb4b5bc4`;
    const headers = {
        'Content-Type': 'application/json'
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    getGroundCollectionDropdowns,
    createGroundRequestCollection
}