import React, { useContext } from "react";
import { Button, Container } from "@mui/material";
import { StoreContext } from "../../Contexts";

const AddPoleButton = () => {
    const appContext = useContext(StoreContext);
    const [potreeViewer] = appContext.potreeViewer;
    const [activePole, setActivePole] = appContext.activePole;
    const [potreeMode, setPotreeMode] = appContext.potreeMode;
    const [markerPlacement, setMarkerPlacement] = appContext.markerPlacement;
    const [measurementMap, setMeasurementMap] = appContext.measurementMap;

    const clearPole = () => {
        potreeViewer.scene.removeAllMeasurements();
        setActivePole(null);
        setPotreeMode(false);
        setMarkerPlacement([]);
        setMeasurementMap({});
    }

    return (
        <Container>
            <Button size="small"
                variant="contained"
                onClick={clearPole}
                sx={{ float: 'right' }}>
                Add New Pole
            </Button>
        </Container>
    )
}

export default AddPoleButton;