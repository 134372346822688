/* eslint-disable */
import React, { useState, useContext } from "react";
import { StoreContext } from '../../Contexts';
import { PoleApi } from "../../api";
import { Snackbar, Alert, LinearProgress } from '@mui/material';
import { StepOption } from "../../Enums";
import { generalTools } from "../../utils/reusable-functions";
import remapMeasurementWithRecord from "../../utils/reusable-functions/remapMeasurementWithRecord";

const withAttachementManager = (Component) => {
    return function (props) {
        const appContext = useContext(StoreContext);
        const [potreeViewer] = appContext.potreeViewer;
        const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
        const [open, setOpen] = useState(false);
        const [measurementMap, setMeasurementMap] = appContext.measurementMap;
        const [step, setStep] = useState(StepOption.Start);
        const [newAttachmentId, setNewAttachmentId] = useState(null);
        const [errors, setErrors] = useState([]);

        const handleSetPointMode = (measurementLabel) => {
            if (!Object.keys(measurementMap).includes(measurementLabel)) {
                const measurment = potreeViewer.measuringTool.startInsertion({
                    showDistances: false,
                    showAngles: false,
                    showCoordinates: false,
                    showArea: false,
                    closed: true,
                    maxMarkers: 1,
                    name: 'Point'
                });

                measurment.addEventListener('marker_dropped', (event) => {
                    // setMarkerPlacement([...potreeViewer.scene.measurements]);
                    // map the point object to the ui. 
                    setMeasurementMap(prevMap => ({
                        ...prevMap, // Copy existing items
                        [measurementLabel]: event.target, // Add/update the new item
                    }));
                });

            }
        }

        const handleStartNewAttachment = () => {
            setNewAttachmentId(generalTools.generateRandomString(6));
            setStep(StepOption.Create);
        }

        const handleCancelMeasurement = async (measurementTypes) => {
            setErrors([]);
            // remove possible unsaved measurments. 
            measurementTypes.map(type => handleRemoveMeasurement(`${type}_${newAttachmentId}`));
            setStep(StepOption.Start);
        }

        const handleClearMeasurements = async (measurementTypes) => {
            //same as cancel, but we don't change steps. 
            setErrors([]);
            // remove possible unsaved measurments. 
            measurementTypes.map(type => handleRemoveMeasurement(`${type}_${newAttachmentId}`));
        }

        const handleRemoveMeasurement = (labelId) => {
            if (Object.keys(measurementMap).includes(labelId)) {
                // make a copy of the measurement of interest. 
                // this is the potree Measure object associated to the measurement you are cancelling. 
                const theMeasure = measurementMap[labelId];

                // a reference to the object directly in potree and not in the measure map. 
                // the measure map will be removed so we can not reference it later. 
                const theMeasureTarget = potreeViewer.scene.measurements.find(measure => measure.uuid === theMeasure.uuid);

                // we don't want this in the measure map anymore so we remove it. 
                setMeasurementMap((prevMap) => {
                    // copy previous map
                    const newMap = { ...prevMap };
                    // remove this item from the measurement map.
                    delete newMap[labelId];
                    return newMap;
                });
                // finally we remove the measurement from potree viewer. 
                theMeasureTarget.removeMarker(theMeasureTarget.points.length - 1);
            }
        }

        const handleSaveMeasurements = async (response, measurementTypes) => {
            measurementTypes.map(type => remapMeasurementWithRecord(
                response,
                type.coord_id ?? type.id,
                newAttachmentId,
                measurementMap,
                setMeasurementMap
            ));

            setActivePole(await PoleApi.getPoleWithAttachementsById(activePole.id));
            setStep(StepOption.Start);
        }

        const handleDropdownChange = (key, e, setFormData) => {
            setFormData(prevState => ({
                ...prevState,
                [key]: e.target.value
            }));
        }

        const handleErrors = (errors) => {
            if (errors?.length) {
                setErrors(errors)
            }
        }

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };

        return (
            <div className={'attachment-manager'} key={newAttachmentId}>
                {errors.map(error =>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={error.severity}>
                            error.message
                        </Alert>
                    </Snackbar>)}
                {!activePole && <LinearProgress />}
                <Component
                    {...props}
                    newAttachmentId={newAttachmentId}
                    handleSetPointMode={handleSetPointMode}
                    handleCancelMeasurement={handleCancelMeasurement}
                    handleRemoveMeasurement={handleRemoveMeasurement}
                    handleStartNewAttachment={handleStartNewAttachment}
                    handleSaveMeasurements={handleSaveMeasurements}
                    handleClearMeasurements={handleClearMeasurements}
                    handleDropdownChange={handleDropdownChange}
                    handleErrors={handleErrors}
                    step={step}
                    setStep={setStep}
                />
            </div>
        );
    }
};

export default withAttachementManager;