/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from '../../Contexts';
import {
  Button,
  Alert,
  Container,
  Card,
  CardContent,
  Tabs,
  Tab,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  Stack,
  Snackbar
} from '@mui/material';
import { PoleApi, TransmissionApi, DistributionApi, CommsApi } from "../../api";
import AttachmentManager from "./AttachmentManager";
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";
import Chip from '@mui/material/Chip';
import { generalTools, measurementTools, locationFunctions } from "../../utils/reusable-functions";
import CustomTabPanel from "../../custom-components/CustomTabPanel";
import { InputType, InteractiveSection, StepOption } from "../../Enums";
import LoadingButton from '@mui/lab/LoadingButton';
import { poleInputTypeObjects } from "../../type-objects/PoleInputTypeObjects";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";


const MeasurementSchema = () => {
  //context state.
  const appContext = useContext(StoreContext);
  const [potreeViewer] = appContext.potreeViewer;
  const [activePole, setActivePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
  const [activePointcloud] = appContext.activePointcloud;
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [measurementMap, setMeasurementMap] = appContext.measurementMap;
  const [potreeMode, setPotreeMode] = appContext.potreeMode;
  const [markerPlacement, setMarkerPlacement] = appContext.markerPlacement;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activeProject] = appContext.activeProject;
  const [activeMeasurementTab, setActiveMeasurementTab] = useState(0);
  const [step, setStep] = useState(StepOption.Create);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setValidationErrors([]);
  };

  const [poleSchemaForForm, setPoleSchemaForForm] = useState({
    pole_tag_1: '',
    pole_tag_2: '',
    pole_tag_3: '',
    pole_owner: "AT&T",
    pole_material: '',
    pole_class: '',
    pole_species: '',
    pole_ground: '',
    pole_length: '',
    fielder: 'Mike',
    pole_field_notes: '',
    reviewer: ''
  });

  const getDropdownData = async () => {
    if (!dropdownData) {
      const response = await PoleApi.fetchPoleDropdownValues();
      setDropdownData(response);
    }
  }

  const getCurrentCameraPosition = () => {
    return potreeViewer.scene.getActiveCamera().position;
  }

  const tabList = [
    {
      id: 'pole',
      label: 'Pole'
    },
    {
      id: 'attachments',
      label: 'Attachments'
    }
  ]

  const tabChange = (e, newValue) => {
    setActiveMeasurementTab(newValue);
  }

  const handleInputChange = (key, e) => {
    setPoleSchemaForForm(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const updateMeasurementIfValueHasChanged = async (key) => {
    // given a key this function will find the corresponding value in the activePole. 
    // activePole is a reflection of the current database state.

    // this is the current state of the value for the in potree viewer.
    // if it is different than what is in the current record then it needs to be saved using the upsert endpoint.
    if (key.includes('transmission')) {
      const thePotreeMeasureValue = measurementMap[key];
      const thePotreeValueAsMeasurement = measurementTools.getHeight(measurementMap[key], measurementMap['pole_base']);
      const keySplit = key.split('_');
      const recordId = keySplit[keySplit.length - 1];
      const theCurrentRecord = activePole.transmission_lines.find((attachment) => attachment.id === recordId);
      // for transmission we need to look at the values of 
      // existing and proposed. 

      if (key.includes('existing')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['existing'] !== thePotreeValueAsMeasurement) {
            await TransmissionApi.upsertTransmission({
              "id": theCurrentRecord.id,
              "existing": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "existing_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (key.includes('proposed')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['proposed'] !== thePotreeValueAsMeasurement) {
            await TransmissionApi.upsertTransmission({
              "id": theCurrentRecord.id,
              "proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }
    }

    // handle distribution
    if (key.includes('distribution')) {
      const thePotreeMeasureValue = measurementMap[key];
      const thePotreeValueAsMeasurement = measurementTools.getHeight(measurementMap[key], measurementMap['pole_base']);
      const keySplit = key.split('_');
      const recordId = keySplit[keySplit.length - 1];
      const theCurrentRecord = activePole['distribution'].find((attachment) => attachment.id === recordId);
      if (key.includes('existing')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['existing'] !== thePotreeValueAsMeasurement) {
            await DistributionApi.upsertDistribution({
              "id": theCurrentRecord.id,
              "existing": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "existing_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (key.includes('proposed')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['proposed'] !== thePotreeValueAsMeasurement) {
            await DistributionApi.upsertDistribution({
              "id": theCurrentRecord.id,
              "proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }
    }

    // handle distribution extra equipment (transformers, regulator, switch)
    if (key.includes('equipment')) {
      const thePotreeMeasureValue = measurementMap[key];
      const thePotreeValueAsMeasurement = measurementTools.getHeight(measurementMap[key], measurementMap['pole_base']);
      const keySplit = key.split('_');
      const recordId = keySplit[keySplit.length - 1];
      const theCurrentRecord = activePole.distribution_equipment.find((attachment) => attachment.id === recordId);
      // if the length of the split is 3 then it is existing.
      // if the length of the split is 4 then it is proposed. 
      if (keySplit.length === 3 && keySplit.slice(0, 2).join("_") === 'equipment_attached') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['equipment_attached'] !== thePotreeValueAsMeasurement) {
            await DistributionApi.upsertExtraDistributionEquipment({
              "id": theCurrentRecord.id,
              "equipment_attached": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "equipment_attached_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (keySplit.length === 3 && keySplit.slice(0, 2).join("_") === 'equipment_bottom') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['equipment_bottom'] !== thePotreeValueAsMeasurement) {
            await DistributionApi.upsertExtraDistributionEquipment({
              "id": theCurrentRecord.id,
              "equipment_bottom": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "equipment_bottom_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (keySplit.length === 4 && keySplit.slice(0, 3).join("_") === 'equipment_attached_proposed') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['equipment_attached_proposed'] !== thePotreeValueAsMeasurement) {
            await DistributionApi.upsertExtraDistributionEquipment({
              "id": theCurrentRecord.id,
              "equipment_attached_proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "equipment_attached_proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (keySplit.length === 4 && keySplit.slice(0, 3).join("_") === 'equipment_bottom_proposed') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['equipment_bottom_proposed'] !== thePotreeValueAsMeasurement) {
            await DistributionApi.upsertExtraDistributionEquipment({
              "id": theCurrentRecord.id,
              "equipment_bottom_proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "equipment_bottom_proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }
    }

    if (key.includes('wireline')) {
      const thePotreeMeasureValue = measurementMap[key];
      const thePotreeValueAsMeasurement = measurementTools.getHeight(measurementMap[key], measurementMap['pole_base']);
      const keySplit = key.split('_');
      const recordId = keySplit[keySplit.length - 1];
      const theCurrentRecord = activePole.wireline_comms.find((attachment) => attachment.id === recordId);
      if (key.includes('proposed')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['proposed'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelineComm({
              "id": theCurrentRecord.id,
              "proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (key.includes('existing')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['existing'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelineComm({
              "id": theCurrentRecord.id,
              "existing": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "existing_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }
    }

    if (key.includes('antenna')) {
      const thePotreeMeasureValue = measurementMap[key];
      const thePotreeValueAsMeasurement = measurementTools.getHeight(measurementMap[key], measurementMap['pole_base']);
      const keySplit = key.split('_');
      const recordId = keySplit[keySplit.length - 1];
      const theCurrentRecord = activePole.wireless_comms.find((attachment) => attachment.id === recordId);

      if (key.includes('proposed') && key.includes('attached')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['antenna_attached_proposed'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelessComm({
              "id": theCurrentRecord.id,
              "antenna_attached_proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "antenna_attached_proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (key.includes('proposed') && key.includes('top')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['antenna_top_proposed'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelessComm({
              "id": theCurrentRecord.id,
              "antenna_top_proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "antenna_top_proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (key.includes('proposed') && key.includes('bottom')) {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['antenna_bottom_proposed'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelessComm({
              "id": theCurrentRecord.id,
              "antenna_bottom_proposed": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "antenna_bottom_proposed_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (keySplit.length === 3 && keySplit.slice(0, 2).join("_") === 'antenna_top') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['antenna_top'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelessComm({
              "id": theCurrentRecord.id,
              "antenna_top": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "antenna_top_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (keySplit.length === 3 && keySplit.slice(0, 2).join("_") === 'antenna_bottom') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['antenna_bottom'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelessComm({
              "id": theCurrentRecord.id,
              "antenna_bottom": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "antenna_bottom_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }

      if (keySplit.length === 3 && keySplit.slice(0, 2).join("_") === 'antenna_attached') {
        // compare and save the existing value 
        if (theCurrentRecord) {
          if (theCurrentRecord['antenna_attached'] !== thePotreeValueAsMeasurement) {
            await CommsApi.upsertWirelessComm({
              "id": theCurrentRecord.id,
              "antenna_attached": thePotreeValueAsMeasurement,
              "updated_by": activePole.reviewer,
              "antenna_attached_coord": vec3ToSerializedString(thePotreeMeasureValue.points[0].position)
            })
          }
        }
      }
    }
    return true;
  }

  const updateAttachmentsIfNeeded = async () => {
    // first we need to determine if values in the measurement map have been altered compared to what is in the database. 
    // the database values can be found in the 
    Object.keys(measurementMap).forEach(async (measurementKey) => {
      await updateMeasurementIfValueHasChanged(measurementKey);
    });
  }

  const updateExistingPole = async () => {
    setLoading(true);
    if (!poleSchemaForForm.reviewer) {
      setValidationErrors(prevState => ([
        ...prevState,
        'reviewer'
      ]));
    }
    else {
      let updateObject = { ...poleSchemaForForm }
      updateObject.id = activePole.id;
      await PoleApi.updatePole(updateObject);

      // need to update any measurement values that differ from what is already saved in the database.
      await updateAttachmentsIfNeeded();
      setActivePole(await PoleApi.getPoleWithAttachementsById(activePole.id));
    }
    setLoading(false);
  }

  const createNewPole = async () => {
    const cameraPosition = getCurrentCameraPosition();
    // 1. add more data to the scene data. add the pointcloud data. we need everything needed to get the pointcloud. 
    // so that we can use it later when we load the pole. 
    const data = {
      "pole_base": JSON.stringify({
        x: markerPlacement[0].points[0].position.x,
        y: markerPlacement[0].points[0].position.y,
        z: markerPlacement[0].points[0].position.z
      }),
      "project_id": activeProject.id,
      "project_name": activePointcloud.collection_name,
      "date_collected": generalTools.getCurrentDateTime(),
      "scene_data": JSON.stringify({ cam: cameraPosition, pc: activePointcloud }), //associated pointcloud is the pointcloud where the pole was used to create the pole in. // we use this to load the pointcloud when only given a pole id. 
      "created_by": 'cooleys@bv.com',
    };
    [data.lng, data.lat] = locationFunctions.getLatLngOfCoordinate(markerPlacement[0], activePointcloud.crs)
    setLoading(true);
    const response = await PoleApi.createNewPole(data);
    const poleId = JSON.parse(response).id;
    setActivePole(await PoleApi.getPoleWithAttachementsById(poleId));
    setLoading(false);
    setStep(StepOption.Update);
  }

  const setPointMode = (measurementLabel) => {
    // only start insertion if the measurement has not yet been taken
    if (!Object.keys(measurementMap).includes(measurementLabel)) {
      setPotreeMode(true);
      const measurment = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point'
      });

      //here you will define all behaviors for when markers change. 
      measurment.addEventListener('marker_dropped', (event) => {
        setMarkerPlacement([...potreeViewer.scene.measurements]);
        // map the point object to the ui. 
        setMeasurementMap(prevMap => ({
          ...prevMap, // Copy existing items
          [measurementLabel]: event.target, // Add/update the new item
        }));
        locationFunctions.getLatLngOfCoordinate(event.target, activePointcloud.crs);
      });
    }
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const openGroundCollect = () => {
    setSidebarModules({
      ...sideBarModules,
      right_top: InteractiveSection.RequestGroundCollection,
    });
  }

  useEffect(() => {
    // this will make sure that the pole agl and pole base will be updated in the form whenever the measurementMap is changed. 
    // without this only the attachments are updated on save. 
    if (Object.keys(measurementMap).includes('pole_agl')) {
      setPoleSchemaForForm(prevState => ({
        ...prevState,
        pole_agl: vec3ToSerializedString(measurementMap['pole_agl'].points[0].position)
      }));
    }

    if (Object.keys(measurementMap).includes('pole_base')) {
      let [lng, lat] = locationFunctions.getLatLngOfCoordinate(measurementMap['pole_base'], activePointcloud.crs);
      setPoleSchemaForForm(prevState => ({
        ...prevState,
        lat: lat,
        lng: lng,
        pole_base: vec3ToSerializedString(measurementMap['pole_base'].points[0].position)
      }));
    }
  }, [measurementMap]);

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (activePole) {
      setStep(StepOption.Update);
      // when active pole changes set the form data so that the values show up in the form. 
      setPoleSchemaForForm({
        pole_tag_1: activePole.pole_tag_1,
        pole_tag_2: activePole.pole_tag_2,
        pole_tag_3: activePole.pole_tag_3,
        pole_owner: "AT&T",
        pole_material: activePole.pole_material,
        pole_class: activePole.pole_class,
        pole_species: activePole.pole_species,
        pole_ground: activePole.pole_ground,
        pole_length: activePole.pole_length,
        fielder: 'Mike',
        pole_field_notes: activePole.pole_field_notes,
        reviewer: activePole.reviewer,
        lat: activePole.lat,
        lng: activePole.lng
      });
    } else {
      setStep(StepOption.Create);
      setActiveMeasurementTab(0);
    }
  }, [activePole])

  if (potreeViewer !== null) {
    // the interface for creating a pole. 
    if (potreeMode && step === StepOption.Create) {
      return (
        <div className="pole-management-wrap">
          <Alert severity="info">Click the base of the pole in the point cloud to set pole location.</Alert>
          <br />
          {markerPlacement.map((marker, index) => (
            <Card key={index} sx={{ minWidth: 275 }}>
              <CardContent>
                <div key={index}>
                  <p>Elevation: {measurementTools.metersToFeet(marker.points[0].position.z)}ft</p>
                  <p>Lat: {locationFunctions.getLatLngOfCoordinate(marker, activePointcloud.crs)[1]}</p>
                  <p>Lng: {locationFunctions.getLatLngOfCoordinate(marker, activePointcloud.crs)[0]}<br /></p>
                </div>
                <LoadingButton size="small" variant="outlined" onClick={createNewPole} loading={loading}>
                  Update
                </LoadingButton>
                <br />
              </CardContent>
            </Card>
          ))}
        </div>
      )
    }

    // the interface for updating a pole.       
    if (activePole && step === StepOption.Update && dropdownData) {
      return (
        <div className="pole-management-wrap">
          <Container sx={{ p: 1 }}>
            <Chip
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                },
              }}
              label={(
                <section>
                  <div> {`Pole ID: ${activePole.id}`}</div>
                  <div> {`Updated: ${activePole.date_updated}`}</div>
                </section>
              )} />
            <LoadingButton size="small" variant="outlined" onClick={updateExistingPole} loading={loading} sx={{ float: 'right' }}>
              Save
            </LoadingButton>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={validationErrors.length}
              autoHideDuration={3000}
              onClose={handleClose}>
              <Alert severity="error">{`missing data: ${JSON.stringify(validationErrors)}`}</Alert>
            </Snackbar>
          </Container>
          {activePole.reviewer ? (
            <Tabs
              value={activeMeasurementTab}
              onChange={tabChange}
              aria-label="measurement tabs"
              size='small'>
              {tabList.map((tab, index) => (
                <Tab key={index} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          ) : (
            <Alert severity="info">
              Before Adding attachment measurements you must specify a reviewer and save.
            </Alert>
          )}
          <CustomTabPanel value={activeMeasurementTab} index={0}>
            {poleInputTypeObjects.map((field, index) => {
              let controlId = `${field.id}_${index}`;
              let inputId = `${field.id}_${field.type}_${index}`;
              switch (field.inputType) {
                case InputType.Text:
                  return (
                    <FormControl key={controlId}
                      margin="dense"
                      fullWidth
                      error={validationErrors.includes(field.id)}>
                      <TextField key={inputId}
                        id={inputId}
                        label={field.label}
                        disabled={field.disabled}
                        value={poleSchemaForForm[field.id]}
                        size='small'
                        onChange={(e) => { handleInputChange(field.id, e) }} />
                      {field.disabled && <FormHelperText>Disabled</FormHelperText>}
                    </FormControl>
                  )
                case InputType.Select:
                  return (
                    <FormControl key={controlId}
                      margin="dense"
                      fullWidth
                      error={validationErrors.includes(field.id)}>
                      <InputLabel key={`${inputId}_label`}
                        id={`${inputId}_label`}>
                        {field.label}
                      </InputLabel>
                      <Select
                        key={inputId}
                        id={inputId}
                        labelId={`${inputId}_label`}
                        size='small'
                        value={poleSchemaForForm[field.id]}
                        onChange={(e) => { handleInputChange([field.id], e) }}>
                        {dropdownData[field.dropdownValueType.type].map((menuItem, index) => (
                          <MenuItem key={index} value={menuItem[field.dropdownValueType.valueKey]}>
                            {menuItem[field.dropdownValueType.labelKey]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>)
                case InputType.MeasurementInputControl:
                  return (
                    <MeasurementInputControl key={controlId}
                      attachmentId={activePole.id}
                      type={field}
                      existingMeasurements={measurementMap}
                      handleSetPointMode={setPointMode} />)
                case InputType.Calculated:
                  return (
                    <FormControl key={controlId}
                      disabled
                      fullWidth
                      margin="dense">
                      <InputLabel key={`${inputId}_label`}
                        htmlFor={inputId}>
                        {field.label}
                      </InputLabel>
                      <Input key={inputId}
                        id={inputId}
                        value={field.getMeasurement(measurementMap[field.id], measurementMap['pole_base'], poleSchemaForForm)} />
                    </FormControl>)
                default: {
                  return (<div>{field.label}, {field.inputType}</div>)
                }
              }
            })}
          </CustomTabPanel>
          <CustomTabPanel value={activeMeasurementTab} index={1}>
            <AttachmentManager />
          </CustomTabPanel>
        </div>
      )
    }

    if (!potreeMode && step !== StepOption) {
      return (
        <Stack spacing={2} sx={{ p: 2 }}>
          <Button size="small" variant="outlined" onClick={() => { setPointMode('pole_base') }}>Set Pole Base</Button>
          <Button size="small"
            variant="outlined"
            onClick={openGroundCollect}>
            Request Ground Collect
          </Button>
        </Stack>
      )
    }
  } else {
    return (
      <div>
      </div>
    );
  }
};

export default MeasurementSchema;
