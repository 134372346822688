import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from '../../Contexts';
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    LinearProgress,
    ButtonGroup
} from '@mui/material';
import vec3ToSerializedString from "../../utils/reusable-functions/vec3ToSerializedString";
import withAttamchementManager from "./AttachmentManagerHOC";
import { DropdownValueType, StepOption } from "../../Enums";
import MeasurementInputControl from "../../custom-components/MeasurementInputControl";
import { guyingMeasurementsObjects } from "../../type-objects";

const DownGuying = ({ anchor, update, ...props }) => {
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole; // the active pole being examined, refers to the values saved in the database.
    const [measurementMap] = appContext.measurementMap;
    const [dropdownData] = appContext.dropdownData;
    const [loading, setLoading] = useState(false);
    const [validationErrors] = useState([]);
    const [formData, setFormData] = useState({
        id: null,
        anchor_id: anchor.id,
        guy_diameter_id: null,
        created_by: activePole.reviewer,
        updated_by: activePole.reviewer,
        pole_id: activePole.id,
        owner_id: 1,
        owner_id_proposed: '',
        guy_diameter_id_proposed: null,
    });

    const addtoAnchor = async () => {
        setLoading(true);
        guyingMeasurementsObjects.downGuyingMeasurementTypes.forEach(type => {
            if (Object.keys(measurementMap).includes(`${type.id}_${props.newAttachmentId}`)) {
                const measurement = measurementMap[`${type.id}_${props.newAttachmentId}`];
                formData[type.id] = type.getMeasurement(measurement, measurementMap.pole_base);
                formData[`${type.id}_coord`] = vec3ToSerializedString(measurement.points[0].position);
            } else {
                formData[type.id] = null;
                formData[`${type.id}_coord`] = null;
            }
        })
        if (!(formData.attach_height || formData.attach_height_proposed)) {
            props.handleErrors({ severity: 'error', message: 'You must make a measurement to save.' });
            setLoading(false);
        } else {
            props.handleErrors(null);
            formData.id = props.newAttachmentId;
            update(prevState => ([
                ...prevState,
                formData
            ]));
            // const response = await MeasurementApi.upsertWirelineComm(formDataCollected);
            // await props.handleSaveMeasurements(response, guyingMeasurementTypes);
            props.setStep(StepOption.Start);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!props.newAttachmentId) {
            props.handleStartNewAttachment();
        }
    })

    if (props.step === StepOption.Create) {
        return (
            <div className="down-guying-manager">
                <strong>
                    <ButtonGroup sx={{ float: "right" }}>
                        <Button variant="outlined" size='small' onClick={addtoAnchor} disabled={loading}>
                            Add
                        </Button>
                        <Button variant="outlined"
                            size='small'
                            color="error"
                            onClick={() => { props.handleCancelMeasurement(guyingMeasurementsObjects.downGuyingMeasurementTypes); }}
                            disabled={loading}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </strong>
                <FormControl fullWidth margin="dense" error={validationErrors.includes('no_type')} size="small">
                    <InputLabel id="down-guy-type-select-label">Down Guy Type</InputLabel>
                    <Select
                        labelId="down-guy-type-select-label"
                        id="down-guy-type-select"
                        value={formData.guy_diameter_id}
                        label="Anchor Type"
                        onChange={(e) => { props.handleDropdownChange('guy_diameter_id', e, setFormData) }}>
                        {dropdownData[DropdownValueType.DownGuyType.type].map((menuItem, index) => (
                            <MenuItem key={index} value={menuItem.id}>{menuItem[DropdownValueType.DownGuyType.labelKey]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {guyingMeasurementsObjects.downGuyingMeasurementTypes.map(type => {
                    return (
                        <MeasurementInputControl key={`${type.id}_${props.newAttachmentId}`}
                            attachmentId={props.newAttachmentId}
                            type={type}
                            existingMeasurements={measurementMap}
                            handleSetPointMode={props.handleSetPointMode} />);
                })}
                {loading && <LinearProgress />}
            </div>
        )
    }
};

const DownGuyingManager = withAttamchementManager(DownGuying);

export default DownGuyingManager;