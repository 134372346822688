const authorizationToken = "effffdb1-3f53-414d-a200-d3a6bb4b5bc4";
const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;

const fetchImageryByBounds = async (collectionName, gpsx1, gpsy1, gpsx2, gpsy2) => {
    if (gpsx1) {
        const url = `${baseUrl}/get_collection_image_data_boundingbox?collection_name=${collectionName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
        return await fetch(url)
            .then(response => response.json())
            .then(data => { return data.body })
            .catch(error => { throw new Error(`API call failed with status code ${error}`) });
    }
};

const fetchImageryByProject = async (projectName, maxRecords = null, prefilter = null) => {
    const url = `${baseUrl}/get_collection_image_data_by_project_name?project_name=${projectName}` +
        (maxRecords ? `&max_records=${maxRecords}` : '') + // default and absolute max is 5,000
        (prefilter ? `&prefilter=${prefilter}` : ''); // selects nth values
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
};

const fetchPanoImageData = async (imageId) => {
    const url = `${baseUrl}/get_collection_image_data_by_id?image_id=${imageId}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body[0] })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchPanoImage = async (imageFolder, imageFilename, bucketName) => {
    // the url 
    // const projectName = "Palmdale";// we don't need to hard code this. it should already be in the url being passed. 
    const fileLocation = encodeURIComponent(`${imageFolder}${imageFilename}`);
    const url = `${baseUrl}/get_presigned_image_url?bucket_name=${bucketName}&image_url=${fileLocation}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return JSON.parse(data['body']) })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchNearestPanoImage = async (collection_name, collection_set, latitude, longitude) => {
    const url = `${baseUrl}/get_collection_image_closest_point?` +
        `collection_name=${collection_name}` +
        `&collection_set=${collection_set}&` +
        `lat=${latitude}&` +
        `lng=${longitude}`
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body[0] })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    fetchImageryByBounds,
    fetchImageryByProject,
    fetchPanoImage,
    fetchPanoImageData,
    fetchNearestPanoImage
}