export let MicrosoftMaps;

function loadBingApi(key) {
    const callbackName = "bingAPIReady";
    let url = `https://www.bing.com/api/maps/mapcontrol?callback=${callbackName}`;
    if (key) {
        url += `&key=${key}`;
    }

    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;
        script.src = url;
        window[callbackName] = () => {
            setTimeout(() => {
                if (window.Microsoft && window.Microsoft.Maps) {
                    // The script has now been loaded
                    // You can now execute your function
                    MicrosoftMaps = window.Microsoft;
                    resolve();
                }
                // this is a hack, but I am not sure of a better way right now. 
                // fix later. 
            }, 500);
        };
        script.onerror = (error) => {
            reject(error);
        };
        document.body.appendChild(script);
    });
}

const getMapBounds = (map) => {
    const bounds = map.getBounds();
    if (bounds) {
        return {
            northEast: {
                latitude: bounds.bounds[0],
                longitude: bounds.bounds[1],
            },
            southWest: {
                latitude: bounds.bounds[2],
                longitude: bounds.bounds[3],
            }
        }
    }
}

const initLayer = (layerId, map, visible) => {
    const { Maps } = window.Microsoft;
    let layer = getLayer(layerId, map);
    if (layer) {
        layer.clear();
    }
    else {
        layer = new Maps.Layer(layerId);
        map.layers.insert(layer);
    }
    layer.setVisible(visible)
    return layer
}

function getLayer(layerId, map) {
    return map?.layers.find(layer => layer.getId() === layerId) ?? null;
}

function resetPins(pins, color) {
    for (var i = 0; i < pins.length; i++) {
        pins[i].setOptions({ color: color });
    }
}

function findPinsInArea(layer, updatedSearchArea, defaultColor, selectColor, setSelectedPins) {
    const { Maps } = window.Microsoft;

    //Ensure that the search area is a valid polygon, should have 4 Locations in it's ring as it automatically closes.
    if (Maps && layer && updatedSearchArea && updatedSearchArea.getLocations().length >= 4) {
        //Get all the pushpins from the pinLayer.
        var pins = layer.getPrimitives();
        resetPins(pins, defaultColor);
        //Using spatial math find all pushpins that intersect with the drawn search area.
        //The returned data is a copy of the intersecting data and not a reference to the original shapes, 
        //so making edits to them will not cause any updates on the map.
        var intersectingPins = Maps.SpatialMath.Geometry.intersection(pins, updatedSearchArea);

        //The data returned by the intersection function can be null, a single shape, or an array of shapes. 
        if (intersectingPins) {
            //For ease of usem wrap individudal shapes in an array.
            if (intersectingPins && !(intersectingPins instanceof Array)) {
                intersectingPins = [intersectingPins];
            }

            var updatedSelectedPins = [];

            //Loop through and map the intersecting pushpins back to their original pushpins by comparing their coordinates.
            for (var j = 0; j < intersectingPins.length; j++) {
                for (var i = 0; i < pins.length; i++) {
                    if (Maps.Location.areEqual(pins[i].getLocation(), intersectingPins[j].getLocation())) {
                        //Set the selected pin color to red.
                        pins[i].setOptions({ color: selectColor });

                        updatedSelectedPins.push(pins[i]);
                        break;
                    }
                }
            }
            setSelectedPins(updatedSelectedPins);
        }
    }
}

export {
    loadBingApi,
    getMapBounds,
    initLayer,
    getLayer,
    findPinsInArea,
    resetPins
}